<template>
  <span
    :id="itemId"
    class="patient-table-item"
  >
    <initials-circle
      first-id="responsive-outer-circle"
      second-id="responsive-inner-circle"
      class="gray-border-circle initials-circle"
      :first="first"
      :second="second"
      :alert="alertCount"
      :inactive="isInactive"
    />
    <div class="patient-info">
      <div>
        <router-link
          :to="patientRoute"
          class="name go-to-patient"
          @click.native="storeScrollPosition"
        >
          {{ name }}
        </router-link>
        <span class="age">{{ age }}</span>
        <span class="gender">{{ gender }}</span>
      </div>
      <span class="mrn"><span class="patient-info-label">{{ $t('mrn') }}</span> {{ mrn }}</span>
      <span class="owl"><span class="patient-info-label">OWL</span> {{ owlId }}</span>
    </div>
  </span>
</template>

<script>
import { Helpers } from '@/mixins/Helpers'
import InitialsCircle from '@/components/common/InitialsCircle'
import { SET_HOME_TAB_SCROLL_POSITION } from '@/store/modules/user/constants.js'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'PatientTableItem',
  components: {
    InitialsCircle
  },
  mixins: [Helpers],
  props: ['first', 'second', 'status', 'alertCount', 'name', 'ccaId', 'ccauId', 'age', 'gender', 'mrn', 'itemId', 'owlId', 'search', 'isInactive'],
  computed: {
    ...mapGetters({
      homeTab: 'getHomeTab'
    }),
    patientRoute () {
      const routeObj = {
        name: (this.alertCount > 0) ? 'ClinicianPatientAlerts' : 'ClinicianPatient',
        params: {
          ccaId: this.$getEncodedId(this.ccaId),
          ccauId: this.$getEncodedId(this.ccauId)
        }
      }

      if (this.search) {
        // only if a search filter is in place do we pass that onto the linked route
        routeObj.query = {
          search: this.$getEncodedId(this.search)
        }
      }
      return routeObj
    }
  },
  methods: {
    ...mapMutations({
      setScrollPosition: SET_HOME_TAB_SCROLL_POSITION
    }),
    storeScrollPosition () {
      this.setScrollPosition({ tab: this.homeTab, value: document.documentElement.scrollTop })
      this.$emit('toPatientDetail', this.itemId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/override.scss';

.patient-table-item {
  display: flex;

  .initials-circle {
    &#responsive-outer-circle {
      width: 50px;
      height: 50px;
      margin-left: 10px;
      margin-right: 22px;

      #responsive-inner-circle {
        width: 46px;
        height: 46px;
        border-width: 3px;

        span:not(.badge) {
          font-size: 18px;
        }
      }
    }
  }

  .patient-info {
    > span,
    > a {
      color: $navy-blue;
    }

    .name {
      margin-right: 12px;
      font-size: 15px;
      font-family: MuseoSans_700;
      display: inline-block;
      font-weight: bold;
      line-height: 1.5;
      color: #66758f;
    }

    .age,
    .gender {
      font-size: 15px;
      font-family: MuseoSans_300;
      font-weight: 300;
      text-transform: capitalize;
    }

    .mrn,
    .owl {
      white-space: nowrap;
      line-height: 16px;
      font-size: 15px;
      font-family: MuseoSans;
      font-weight: 100;

      .patient-info-label {
        font-size: 12px;
      }
    }
  }
}
</style>
