<template>
  <div class="input-group search-input">
    <input
      ref="searchInputRef"
      type="text"
      class="form-control"
      :value="searchString"
      :placeholder="placeholder"
      @keyup="search($event)"
    >
    <div class="input-group-append">
      <i
        v-if="!searchString"
        class="fal fa-search"
      />
      <button
        v-if="searchString"
        class="btn btn-bare"
        @click="clearSearch"
      >
        <i class="fal fa-times" />
      </button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'

export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    focus: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: function () {
        return this.$t('search')
      }
    }
  },
  data () {
    return {
      // this mananges the interim state between the real-time value seen in the input
      // and the lagging (debounced) new `value` coming in via prop changes
      searchString: ''
    }
  },
  computed: {
    ...mapGetters({
      currentBrowser: 'getCurrentBrowser'
    }),
    debounceTime () {
      return this.currentBrowser && this.currentBrowser.isExplorer ? 300 : 100
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        this.searchString = newValue
      }
    },
    // this is needed to prevent search string from carrying over across route changes
    $route () {
      // needed to remove this to fix OWL-9777. I guess we're keeping state across routes now ¯\_(ツ)_/¯
      // this.clearSearch()
    }
  },
  created () {
    if (this.focus) {
      this.$nextTick().then(() => {
        this.$refs.searchInputRef.focus()
      })
    }

    this.debouncedEmit = debounce(this.emitInput, this.debounceTime)
  },
  methods: {
    emitInput () {
      this.$emit('input', this.searchString)
    },
    search (event) {
      this.searchString = event.target.value
      this.debouncedEmit()
    },
    clearSearch () {
      this.searchString = ''
      this.emitInput()
      this.$refs.searchInputRef.focus()
    }
  }
}
</script>
