<template>
  <div class="my-programs-nav">
    <ul class="nav nav-tabs">
      <router-link
        v-for="(tab, index) in tabs"
        :key="index"
        tag="li"
        :to="{name: tab.href}"
        class="nav-item"
      >
        <a class="nav-link"><span>{{ tab.label }}</span></a>
      </router-link>
      <li class="search">
        <filter-selector
          v-if="showPatientSelector || showAlertsSelector"
          :filter-element="filterElement"
          :inactive-string="inactiveString"
          :respondent-label="respondentLabel"
          @filtersChanged="$emit('filtersChanged')"
        />
        <search-input
          v-if="showSearch"
          v-model="search"
          :placeholder="placeholder"
          @input="emitSearch"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput'
import FilterSelector from '@/components/common/FilterSelector'
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'ClinicianLayoutNav',
  components: {
    SearchInput,
    FilterSelector
  },
  mixins: [Helpers],
  props: ['tabs', 'showSearch', 'placeholder', 'showPatientSelector', 'showAlertsSelector', 'initSearch'],
  data () {
    return {
      search: ''
    }
  },
  computed: {
    inactiveString () {
      return this.showAlertsSelector ? 'Acknowledged' : 'Inactive'
    },
    filterElement () {
      return this.showAlertsSelector ? 'Alerts' : this.respondentLabel ? 'Respondents' : 'Patients'
    },
    respondentLabel () {
      return this.$route.name === 'ClinicianRespondentsList'
    }
  },
  mounted () {
    const queryParams = Object.assign({}, this.$route.query)
    if (queryParams.search || queryParams.filter) {
      this.search = queryParams.search ? this.$getDecodedId(queryParams.search) : queryParams.filter
      this.emitSearch()
      delete queryParams.search
      delete queryParams.filter
      window.history.replaceState('removed query params', this.$route.title, this.$route.path)
    }
  },
  methods: {
    clearSearch () {
      this.search = ''
    },
    emitSearch () {
      this.$emit('searching', this.search)
    }
  }
}
</script>
