<template>
  <div
    v-if="!hideStatusSelector || userHasAccessToPatientsSelector()"
    class="filters-container"
    :class="{'no-patient-selector': !userHasAccessToPatientsSelector()}"
  >
    <div
      class="btn-group"
      :class="{show: opened}"
    >
      <a
        class="trigger"
        @click="opened = !opened"
      ><span>{{ selectedLabel }}</span></a>
      <i
        class="fas filter-toggle"
        :class="{'fa-caret-down': !opened, 'fa-caret-up': opened}"
        @click="opened = !opened"
      />
      <div
        class="dropdown-menu"
        :class="{show: opened}"
      >
        <i
          class="fal fa-times"
          @click="opened = false"
        />
        <div
          v-for="item in ownerSelectorItems"
          :key="item.value"
          class="dropdown-item"
        >
          <input
            :id="item.value"
            v-model="ownerSelector"
            type="radio"
            name="ownerSelector"
            :value="item.value"
            style="display:none"
          >
          <i
            :class="{'far fa-dot-circle': ownerSelector === item.value, 'far fa-circle': ownerSelector !== item.value}"
            @click="ownerSelector = item.value"
          />
          <label :for="item.value">{{ $t(item.label, {status: ''}) }}</label>
        </div>
        <hr v-if="userHasAccessToPatientsSelector() && !hideStatusSelector">
        <span
          v-if="activeSelectorRequiredError && !hideStatusSelector"
          class="error"
        >{{ $t('atLeast1Status') }}</span>
        <div
          v-if="!hideStatusSelector"
          class="dropdown-item"
        >
          <input
            id="active"
            v-model="activeSelector"
            type="checkbox"
            name="activeSelectorActive"
            value="active"
            style="display:none"
          >
          <i
            :class="{'fal fa-check-square': activeSelector.includes('active'), 'fal fa-square': !activeSelector.includes('active')}"
            @click="updateActiveSelector('active')"
          />
          <label for="active">Active {{ filterElementLabel.toLowerCase() }}</label>
        </div>
        <div
          v-if="!hideStatusSelector"
          class="dropdown-item"
        >
          <input
            id="inactive"
            v-model="activeSelector"
            type="checkbox"
            name="activeSelectorInactive"
            value="inactive"
            style="display:none"
          >
          <i
            :class="{'fal fa-check-square': activeSelector.includes('inactive'), 'fal fa-square': !activeSelector.includes('inactive')}"
            @click="updateActiveSelector('inactive')"
          />
          <label for="inactive">{{ inactiveString }} {{ filterElementLabel.toLowerCase() }}</label>
        </div>
        <div class="button-container">
          <button
            type="button"
            :disabled="activeSelectorRequiredError"
            class="btn btn-secondary btn-block"
            @click="applyFilters"
          >
            {{ 'Apply Filter' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import { Helpers } from '@/mixins/Helpers'
import {
  PATIENT_SELECTOR_ALL,
  PATIENT_SELECTOR_MY_PATIENTS,
  PATIENT_SELECTOR_SUPERVISED_PATIENTS,
  PATIENT_SELECTOR_ALL_LABEL,
  PATIENT_SELECTOR_MY_PATIENTS_LABEL,
  PATIENT_SELECTOR_SUPERVISED_PATIENTS_LABEL,
  PATIENT_SELECTOR_ALL_RESPONDENTS,
  PATIENT_SELECTOR_MY_RESPONDENTS,
  PATIENT_SELECTOR_SUPERVISED_RESPONDENTS
} from '@/data/patientSelectorOptions'

export default {
  name: 'FilterSelector',
  mixins: [UserManagementComponentMixin, Helpers],
  props: {
    filterElement: {
      default: '',
      type: String
    },
    inactiveString: {
      type: String,
      default: 'Inactive'
    },
    respondentLabel: {
      type: Boolean,
      default: false
    },
    hideStatusSelector: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loader: null,
      opened: false,
      selectedLabel: '',
      ownerSelector: this.getOwnerSelectorInitValue(),
      activeSelector: this.getActiveSelectorInitValue(),
      selectedSettingsChanged: false
    }
  },
  computed: {
    ...mapGetters({
      settings: 'generalSettings',
      loggedInUser: 'loggedInUser',
      uiSettings: 'getUiSettings'
    }),
    activeStatusSettingKey () {
      return `${this.filterElement.toLowerCase()}StatusSelector`
    },
    alertsView () {
      return this.filterElement === 'Alerts'
    },
    activeSelectorRequiredError () {
      return !this.hideStatusSelector && this.activeSelector && !this.activeSelector.length
    },
    filterElementLabel () {
      return this.filterElement
    },
    ownerSelectorItems () {
      const items = []
      if (this.userHasAccessToPatientsSelectorMyPatients()) {
        const label = this.respondentLabel ? PATIENT_SELECTOR_MY_RESPONDENTS : PATIENT_SELECTOR_MY_PATIENTS_LABEL
        items.push({ value: PATIENT_SELECTOR_MY_PATIENTS, label })
      }
      if (this.userHasAccessToPatientsSelectorAllPatients()) {
        const label = this.respondentLabel ? PATIENT_SELECTOR_ALL_RESPONDENTS : PATIENT_SELECTOR_ALL_LABEL
        items.push({ value: PATIENT_SELECTOR_ALL, label })
      }
      if (this.userHasAccessToPatientsSelectorSupervisedPatients()) {
        const label = this.respondentLabel ? PATIENT_SELECTOR_SUPERVISED_RESPONDENTS : PATIENT_SELECTOR_SUPERVISED_PATIENTS_LABEL
        items.push({ value: PATIENT_SELECTOR_SUPERVISED_PATIENTS, label })
      }
      return items
    }
  },
  watch: {
    'filterElement' () {
      this.settingsChanged()
    },
    'uiSettings.alertsStatusSelector' () {
      this.settingsChanged()
    },
    'uiSettings.respondentsStatusSelector' () {
      this.settingsChanged()
    },
    'uiSettings.patientsStatusSelector' () {
      this.settingsChanged()
    }
  },
  created () {
    this.setFilterText()
  },
  methods: {
    settingsChanged () {
      this.activeSelector = this.getActiveSelectorInitValue()
      this.setFilterText()
    },
    getActiveSelectorInitValue () {
      const setting = this.$store.getters.getUiSettings[`${this.filterElement.toLowerCase()}StatusSelector`]
      if (setting && setting.selected) {
        switch (setting.selected) {
          case 'all':
            return ['active', 'inactive']
          case 'active':
            return ['active']
          case 'inactive':
          case (this.inactiveString.toLowerCase()):
            return ['inactive']
          default:
            return ['active']
        }
      }
      return ['active']
    },
    getOwnerSelectorInitValue () {
      const uiSettingsValue = this.$store.getters.getUiSettings.patientSelector
      if (uiSettingsValue) {
        if (this.userHasAccesToOption(uiSettingsValue.selected)) {
          return uiSettingsValue.selected
        }
      }
      if (this.loggedUserIsClinician()) {
        return PATIENT_SELECTOR_MY_PATIENTS
      }
      if (this.loggedUserIsSupervisor()) {
        return PATIENT_SELECTOR_SUPERVISED_PATIENTS
      }
      if (this.loggedUserIsStaff()) {
        return PATIENT_SELECTOR_ALL
      }
    },
    userHasAccesToOption (option) {
      const functionSufix = this.capitalizeString(option)
      const fn = this[`userHasAccessToPatientsSelector${functionSufix}Patients`]
      if (typeof fn === 'function') {
        return fn()
      }
      return false
    },
    updateActiveSelector (value) {
      const index = this.activeSelector.findIndex(v => v === value)
      if (index === -1) {
        this.activeSelector.push(value)
      } else {
        this.activeSelector.splice(index, 1)
      }
    },
    getOnwerText () {
      const statusText = this.getStatusText()
      let index = ''
      switch (this.ownerSelector) {
        case 'my':
          index = this.respondentLabel ? PATIENT_SELECTOR_MY_RESPONDENTS : PATIENT_SELECTOR_MY_PATIENTS_LABEL
          return this.alertsView ? `${this.$t(index, { status: '' })}' ${statusText}` : `${this.$t(index, { status: statusText })}`
        case 'all':
          index = this.respondentLabel ? PATIENT_SELECTOR_ALL_RESPONDENTS : PATIENT_SELECTOR_ALL_LABEL
          return this.alertsView ? `${this.$t(index, { status: '' })}' ${statusText}` : `${this.$t(index, { status: statusText })}`
        case 'supervised':
          index = this.respondentLabel ? PATIENT_SELECTOR_SUPERVISED_RESPONDENTS : PATIENT_SELECTOR_SUPERVISED_PATIENTS_LABEL
          return this.alertsView ? `${this.$t(index, { status: '' })}' ${statusText}` : `${this.$t(index, { status: statusText })}`
      }
    },
    getStatusText () {
      const status = this.activeSelector
      if (status.length === 1 && status[0] !== 'active') {
        return this.inactiveString.toLowerCase()
      }
      return ''
    },
    getFilterElementText () {
      return this.alertsView ? `${this.filterElement.toLowerCase()}` : ''
    },
    getSelectedLabel () {
      return `${this.getOnwerText()} ${this.getFilterElementText()}`
    },
    setFilterText () {
      this.selectedLabel = this.getSelectedLabel()
    },
    applyFilters () {
      this.selectedSettingsChanged = false
      this.loader = this.$loading.show()
      if (!this.activeSelectorRequiredError) {
        this.opened = false
        this.emitPatientSelectorChange(this.ownerSelector, 'patientSelector')
        if (!this.hideStatusSelector) {
          this.emitPatientSelectorChange(this.getStatusValue(), this.activeStatusSettingKey)
        }
        if (this.selectedSettingsChanged) {
          this.$store.dispatch('UPDATE_UI_SETTINGS', this.uiSettings).then(() => {
            this.$emit('filtersChanged')
            this.setFilterText()
          })
        }
      }
    },
    emitPatientSelectorChange (val, settingKey) {
      // If selecting the same option prevent reload.
      const previousValue = this.uiSettings && this.uiSettings[settingKey] ? this.uiSettings[settingKey].selected : null
      if (previousValue && previousValue === val) {
        this.loader.hide()
        return false
      }
      // Save new user preferences
      this.$store.dispatch('UPDATE_UI_SETTING_VALUE', { settingKey: settingKey, settingValue: { selected: val } })
      // Update Global alerts.
      this.$store.dispatch('GET_CLINICIAN_ALERTS_COUNT').then(() => {
        this.loader.hide()
      })
      this.selectedSettingsChanged = true
    },
    getStatusValue () {
      if (this.activeSelector.length === 2) {
        return 'all'
      }
      return this.inactiveString && this.activeSelector[0] === 'inactive' ? this.inactiveString.toLowerCase() : this.activeSelector[0]
    }
  }
}
</script>
