<template>
  <div
    class="modal fade show crud-modal"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="title">
            <h3 v-if="title">
              {{ title }}
            </h3>
            <slot name="extraTitleContent" />
          </div>
          <div>
            <slot name="extraHeaderInfo" />
            <i
              class="fal fa-times"
              @click="close"
            />
          </div>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <div class="modal-footer">
          <div class="extra-buttons">
            <slot name="bottomPanelLeft" />
          </div>
          <span class="error">{{ customError }}</span>
          <button
            type="button"
            class="btn btn-outline-secondary"
            @click="close"
          >
            {{ closeText }}
          </button>
          <slot
            name="extraActionButtons"
            @close="close()"
          />
          <button
            type="button"
            class="btn btn-secondary"
            @click="$emit('save')"
          >
            {{ saveText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'CrudModal',
  mixins: [Helpers],
  props: {
    /* eslint-disable */
    closeText: {
      default: function () {
        return this.$t('cancel')
      }
    },
    saveText: {
      default: function () {
        return this.$t('save')
      }
    },
    title: {
      default: ''
    },
    customError: {
      default: ''
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    promptMessage: {
      default: ''
    }
    /* eslint-enable */
  },
  computed: {
    ...mapGetters({
      formDirty: 'getActiveFormDirty'
    })
  },
  created () {
    document.body.classList.add('overlay-opened')
  },
  destroyed () {
    document.body.classList.remove('overlay-opened')
  },
  methods: {
    close () {
      if (typeof this.promptBeforeClose === 'function' && this.formDirty) {
        this.promptBeforeClose().then(() => {
          this.$emit('close')
        })
      } else {
        this.$emit('close')
      }
    },
    promptBeforeClose () {
      let message = this.$t('updatesNotSaved')
      if (this.promptMessage) {
        message = this.isUpdate ? this.$t('itemWontBeSaved', { item: this.promptMessage }) : this.$t('itemWontBeAdded', { item: this.promptMessage })
      }
      return new Promise((resolve) => {
        const promptOptions = {
          title: this.$t('areYouSureToExit'),
          message: message,
          cancelButton: this.$t('noResume'),
          okButton: this.$t('yesExit')
        }
        this.$promptBeforeAction(promptOptions, () => {
          resolve()
        })
      })
    }
  }
}
</script>
