import { DateTimeHelper } from '@/mixins/DateTimeHelper'
export const LastLoginAndLastActivity = {
  mixins: [DateTimeHelper],
  computed: {
    lastLoginDate () {
      const haveLoginDate = this.clinicalUserEditing && this.clinicalUserEditing.account_information.last_login
      return haveLoginDate ? this.getLastLoginDate() : this.getNoLastLogin()
    },
    lastActivityDate () {
      const valid = this.clinicalUserEditing.account_information.last_activity
      return valid ? this.$toLocal(this.clinicalUserEditing.account_information.last_activity.datetime, 'MMM DD, YYYY') : this.$t('none')
    }
  },
  methods: {
    getNoLastLogin () {
      return this.$t('none')
    },
    getLastLoginDate () {
      return this.$toLocal(this.clinicalUserEditing.account_information.last_login, 'MMM DD, YYYY')
    }
  }
}
