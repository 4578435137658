<template>
  <div
    :id="firstId"
    class="navbar-outer-circle"
    :class="{'has-alert': hasAlert, 'inverse': inverse, 'inactive': inactive}"
  >
    <div
      :id="secondId"
      class="navbar-inner-circle"
    >
      <div>
        <span
          v-if="hasAlert"
          class="badge"
        >{{ alert }}</span>
        <span class="first-letter">{{ firstLetter }}</span>
        <span class="second-letter">{{ secondLetter }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InitialsCircle',
  props: {
    first: {
      default: '',
      type: String
    },
    second: {
      default: '',
      type: String
    },
    firstId: {
      type: String,
      default: 'outer-circle'
    },
    secondId: {
      type: String,
      default: 'inner-circle'
    },
    alert: {
      type: [String, Number],
      default: ''
    },
    inactive: {
      type: Boolean,
      default: false
    },
    inverse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasAlert () {
      return this.alert && parseInt(this.alert) > 0
    },
    firstLetter () {
      return (this.first) ? (this.first.length === 1) ? this.first : this.first.charAt(0).toUpperCase() : ''
    },
    secondLetter () {
      return (this.second) ? (this.second.length === 1) ? this.second : this.second.charAt(0).toUpperCase() : ''
    }
  }
}
</script>
