<template>
  <i
    v-if="statusesWithIcons.includes(status)"
    class="pointer"
    :class="statusIconClass"
    @click="toggle"
  />
</template>

<script>
export default {
  name: 'SessionIconButton',
  props: {
    status: {
      default: null,
      type: Number
    }
  },
  data () {
    return {
      statusesWithIcons: [1, 3, 6]
    }
  },
  computed: {
    statusIconClass () {
      let classText = ''
      switch (this.status) {
        case 1:
          classText = 'fas fa-exclamation-circle overdue'
          break
        case 3:
          classText = 'fas fa-minus-circle incomplete'
          break
        case 6:
          classText = 'fas fa-exclamation-circle due-today'
          break
      }
      return classText
    }
  },
  methods: {
    toggle () {
      this.$emit('changed', this.status)
    }
  }
}
</script>
<style lang="scss" scoped>
  .pointer:hover {
    cursor: pointer;
  }
  .overdue {
    color: #f57723;
  }
  .incomplete {
    color: #f5dc23;
  }
  .due-today {
    color: #f5a623;
  }
</style>
