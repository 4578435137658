<template>
  <span
    v-if="isCompletedSession && lastestSessionDate"
    class="latest-session-item"
  >
    {{ lastestSessionDate }}
  </span>
  <div v-else>
    {{ $t('none') }}
  </div>
</template>

<script>
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { SESSION_STATUS_COMPLETED } from '@/data/lastSessionStatus'
export default {
  name: 'LatestCompletedSessionItem',
  mixins: [DateTimeHelper],
  props: ['data', 'source'],
  computed: {
    status () {
      // Since the schedule list has a different data format we are extracting status in 2 different ways
      switch (this.source) {
        case 'scheduleListTable':
          return this.data.latest_session_data && this.data.latest_session_data.status ? parseInt(this.data.latest_session_data.status) : null
        case 'patientDetail':
          return parseInt(this.data.status)
        case 'patientsByProgram':
        case 'patientsList':
        case 'scheduleListTableByDudeDate':
          return this.data.latest_completed_session_status ? parseInt(this.data.latest_completed_session_status) : null
        default:
          return null
      }
    },
    isCompletedSession () {
      return this.status && this.status === SESSION_STATUS_COMPLETED
    },
    lastestSessionDate () {
      switch (this.source) {
        case 'scheduleListTable':
          return this.$toLocal(this.data.latest_session_data.completed_date, 'MMM DD, YYYY')
        case 'patientDetail':
          return this.$toLocal(this.data.completed_date, 'MMM DD, YYYY')
        case 'patientsByProgram':
        case 'patientsList':
        case 'scheduleListTableByDudeDate':
          return this.$toLocal(this.data.latest_completed_session_completed_date, 'MMM DD, YYYY')
        default:
          return null
      }
    }
  }
}
</script>
