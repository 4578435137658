const caregiversData = [
  { key: 3, value: 'Mother' },
  { key: 4, value: 'Father' },
  { key: 5, value: 'Guardian' },
  { key: 6, value: 'Stepfather' },
  { key: 7, value: 'Stepmother' },
  { key: 8, value: 'Grandmother' },
  { key: 9, value: 'Grandfather' },
  { key: 10, value: 'Uncle' },
  { key: 11, value: 'Aunt' },
  { key: 12, value: 'Great Uncle' },
  { key: 13, value: 'Great Aunt' },
  { key: 14, value: 'Niece' },
  { key: 15, value: 'Nephew' },
  { key: 16, value: 'Cousin' },
  { key: 17, value: 'Brother' },
  { key: 18, value: 'Sister' },
  { key: 19, value: 'Half Brother' },
  { key: 20, value: 'Half Sister' },
  { key: 21, value: 'Ward' },
  { key: 22, value: 'Spouse' },
  { key: 23, value: 'Partner' },
  { key: 24, value: 'Girlfriend' },
  { key: 25, value: 'Boyfriend' },
  { key: 26, value: 'Teacher' },
  { key: 27, value: 'Religious Leader' },
  { key: 28, value: 'Friend' },
  { key: 29, value: 'Coach' },
  { key: 30, value: 'Foster Mother' },
  { key: 31, value: 'Foster Father' },
  { key: 32, value: 'Mother1' },
  { key: 33, value: 'Mother2' },
  { key: 34, value: 'Father1' },
  { key: 35, value: 'Father2' },
  { key: 36, value: 'Guardian1' },
  { key: 37, value: 'Guardian2' },
  { key: 38, value: 'Self' },
  { key: 39, value: 'Son' },
  { key: 40, value: 'Daughter' },
  { key: 41, value: 'Grandson' },
  { key: 42, value: 'Granddaughter' },
  { key: 43, value: 'Employer' },
  { key: 44, value: 'Other' },
  { key: 45, value: 'Foster Parent' },
  { key: 46, value: 'Adult Sibling' },
  { key: 47, value: 'Extended Family' },
  { key: 48, value: 'Grandparent' },
  { key: 49, value: 'Institution' },
  { key: 50, value: 'Step Parent' },
  { key: 51, value: 'Aunt or Uncle' },
  { key: 52, value: 'Unknown' },
  { key: 53, value: 'Care Giver' },
  { key: 54, value: 'Child' },
  { key: 55, value: 'Foster Child' },
  { key: 56, value: 'Grandchild' },
  { key: 57, value: 'Parent' },
  { key: 58, value: 'Stepchild' },
  { key: 59, value: 'Sibling' }
]

export default caregiversData
