<template>
  <div class="last-owl-session-detail-view">
    <div class="line-break" />
    <div
      v-for="(teamMember, index) in filteredAndOrderedTeamDetail"
      :key="index"
    >
      <teamMemberDetail
        :team-member="teamMember"
        @openEditMeasure="openEditMeasure"
      />
    </div>
    <ClinicianEditMeasureResponses
      v-if="measureToEditData"
      :measure="measureToEditData.measure"
      :patient="measureToEditData.patient"
      @finish="finish"
    />
  </div>
</template>

<script>
import { PatientHelper } from '@/mixins/PatientHelper'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import TeamMemberDetail from '@/components/common/TeamMemberDetail'
import ClinicianEditMeasureResponses from '@/components/clinician/measures/ClinicianEditMeasureResponses'
import { mapGetters } from 'vuex'
import lastSesionStatusText from '@/data/lastSesionStatusText'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import { ACTIONS } from '@/store/modules/programList/constants'

export default {
  name: 'LastOwlSessionDetail',
  components: { ClinicianEditMeasureResponses, TeamMemberDetail },
  mixins: [PatientHelper, LastSessionDetail, UserManagementComponentMixin],
  props: ['patient', 'mutationText'],
  data () {
    return {
      measureToEditData: null,
      lastSesionStatusText: lastSesionStatusText
    }
  },
  computed: {
    ...mapGetters({
      formDirty: 'getActiveFormDirty'
    }),
    filteredAndOrderedTeamDetail () {
      if (!Object.values(this.patient.teamDetail).length) {
        return []
      }
      return [...this.patient.teamDetail]
    }
  },
  methods: {
    finish () {
      this.measureToEditData = null
      if (this.formDirty) {
        this.$toast.success({ message: this.$t('measureResponsesUpdated') })
      }
      this.updateTeamLastSession()
      this.updateListAndHeaderLastSession()
    },
    updateTeamLastSession (measure, patient) {
      const params = { ccaId: this.patient.ccaId, programId: this.patient.programId }
      this.$store.dispatch('GET_TEAM_LATEST_SESSION', params).then((response) => {
        const patient = { ...this.patient }
        patient.teamDetail = response
        this.setLastSessionDetail(patient)
      }).catch((e) => {
        this.$handleApiError(e)
      })
    },
    updateListAndHeaderLastSession () {
      if (this.mutationText.indexOf('programs') > -1) {
        const action = this.$store.getters.programsTab === 'my' ? ACTIONS.getMyPrograms : ACTIONS.getAllPrograms
        this.$store.dispatch(action).catch((e) => {
          this.$handleApiError(e)
        })
      }
      if (this.mutationText.indexOf('patient') > -1) {
        this.getRespondentLatestSession()
      }
      this.$emit('updateLastSession')
    },
    openEditMeasure (params) {
      this.measureToEditData = params
    }
  }
}
</script>
