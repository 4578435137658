import { mapGetters } from 'vuex'
import { STATUS_NOT_INVITED, STATUS_INVITATION_SENT } from '@/data/userStatusValues'

export const SettingsUserExtraHeaderMixin = {
  computed: {
    ...mapGetters({
      userInEdition: 'getSettingEditingInForm'
    }),
    showUsername () {
      return this.userInEdition.person_information.user_name && this.canShowUsername
    },
    canShowUsername () {
      return !(this.userNeedSetup || this.userInNeedSetupStatus)
    },
    userNeedSetup () {
      return this.userInEdition.account_information.need_setup
    },
    userInNeedSetupStatus () {
      return [STATUS_NOT_INVITED, STATUS_INVITATION_SENT].includes(Number(this.userInEdition.account_information.status))
    }
  }
}
