<template>
  <FilterContent
    v-model="selected"
    :opened="opened"
    :selected-label="selectedLabel"
    :selected="selected"
    :items="items"
    @toggleOpened="opened = !opened"
    @close="close"
    @applyFilter="applyFilter"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import {
  STATUS_INACTIVE,
  STATUS_ACTIVE,
  STATUS_INVITATION_SENT,
  STATUS_PASSWORD_RESET,
  STATUS_NOT_INVITED
} from '@/data/userStatusValues'
import { Helpers } from '@/mixins/Helpers'
import FilterContent from '@/components/admin/settings/common/FilterContent'
const NO_CONTACT_INFO = 999

export default {
  name: 'AccountStatusFilter',
  components: { FilterContent },
  mixins: [Helpers],
  props: ['hideNoContact'],
  data () {
    return {
      opened: false,
      selected: this.initSelected(),
      selectedLabel: ''
    }
  },
  computed: {
    ...mapGetters({
      uiSettings: 'getUiSettings'
    }),
    items () {
      const items = [
        { value: STATUS_ACTIVE, label: this.$t('activeAndCanSignIn') },
        { value: STATUS_NOT_INVITED, label: this.$t('activeNoSignIn') },
        { value: STATUS_INVITATION_SENT, label: this.$t('activeInvited') },
        { value: STATUS_INACTIVE, label: this.$t('inactive'), showDividerBottom: !this.hideNoContact }
      ]
      if (!this.hideNoContact) {
        items.push({ value: NO_CONTACT_INFO, label: 'Has no contact info' })
      }
      return items
    }
  },
  created () {
    this.setSelectedLabel()
  },
  methods: {
    close () {
      this.opened = false
      this.selected = this.initSelected()
    },
    initSelected () {
      const statusSetting = this.$store.getters.getUiSettings.accountStatusSelector
      const contactInfoSetting = this.$store.getters.getUiSettings.noContactInfoselector
      const selected = statusSetting ? [...statusSetting.selected] : [STATUS_ACTIVE, STATUS_NOT_INVITED, STATUS_INVITATION_SENT, STATUS_INACTIVE]
      if (contactInfoSetting && contactInfoSetting.selected) {
        selected.push(NO_CONTACT_INFO)
      }

      if (selected.includes(STATUS_ACTIVE) && !selected.includes(STATUS_PASSWORD_RESET)) {
        selected.push(STATUS_PASSWORD_RESET)
      }
      return selected
    },
    applyFilter () {
      this.opened = false
      let selected = [...this.selected]
      let noContactInfo = false
      const noContactInfoIndex = selected.findIndex(s => s === NO_CONTACT_INFO)
      if (noContactInfoIndex !== -1) {
        noContactInfo = true
        selected.splice(noContactInfoIndex, 1)
      }

      if (selected.includes(STATUS_ACTIVE)) {
        if (!selected.includes(STATUS_PASSWORD_RESET)) {
          selected.push(STATUS_PASSWORD_RESET)
        }
      } else {
        selected = selected.filter(item => item !== STATUS_PASSWORD_RESET)
      }
      this.emitPatientSelectorChange([...new Set(selected)], 'accountStatusSelector')
      this.emitPatientSelectorChange(noContactInfo, 'noContactInfoselector')
      this.$store.dispatch('UPDATE_UI_SETTINGS', this.uiSettings).then(() => {
        this.$emit('filtersChanged')
        this.setSelectedLabel()
      })
    },
    emitPatientSelectorChange (val, settingKey) {
      // If selecting the same option prevent reload.
      const previousValue = this.uiSettings && this.uiSettings[settingKey] ? this.uiSettings[settingKey].selected : null
      if (previousValue && previousValue === val) {
        return false
      }
      // Save new user preferences
      this.$store.dispatch('UPDATE_UI_SETTING_VALUE', { settingKey: settingKey, settingValue: { selected: val } })
    },
    getSelectedLabel () {
      if (this.selected.length === 0) {
        return this.$t('select')
      }
      let label
      const inactiveSelected = this.selected.includes(STATUS_INACTIVE)
      const noContactInfoSelected = this.selected.includes(NO_CONTACT_INFO)

      if (this.selected.includes(STATUS_ACTIVE) && this.selected.includes(STATUS_NOT_INVITED) && this.selected.includes(STATUS_INVITATION_SENT)) {
        label = inactiveSelected ? this.capitalizeString(this.$t('allUsers')) : this.$t('activeUsers')
      } else if (this.selected.length === 1 && inactiveSelected) {
        label = this.$t('inactiveUsers')
      } else if (this.selected.length <= 3 && !inactiveSelected) {
        label = this.$t('someActiveUsers')
      } else {
        label = this.$t('someUsers')
      }
      return noContactInfoSelected ? `${label} *** ${this.$t('withNoContactInfo')}` : label
    },
    setSelectedLabel () {
      this.selectedLabel = this.getSelectedLabel()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
