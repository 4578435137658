<template>
  <div class="clinician-patients-list">
    <overlay
      v-if="routematch || customMeasure || addRespondentToPatient"
      :prompt-before-close="promptBeforeClose"
      :width="width"
      :show-arrow-back="showArrowBack"
      @close="closeOverlay"
      @goBack="goBack"
    >
      <template slot="content">
        <div v-if="customMeasure">
          <clinician-customize-measure
            :measure-id="customMeasure"
            @finish="closeOverlay"
          />
        </div>
        <clinician-respondent-form-wrapper
          v-else-if="addRespondentToPatient"
          :cca-id="addRespondentToPatient"
          @finished="finished"
        />
        <router-view v-else />
      </template>
    </overlay>
    <detail-view
      v-if="detailViewOpened && lastSessionDetail"
      @close="closeLastSessionDetail"
    >
      <template slot="title">
        <div class="latestDetailTitle">
          <strong>{{ lastSessionDetail.first_name }}'s {{ $t('latestMeasures') }}</strong>
        </div>
      </template>
      <template slot="content">
        <LastOwlSessionDetail
          :patient="lastSessionDetail"
          mutation-text="programs"
        />
        <div
          v-if="showAddRespondent"
          class="last-owl-session-detail-view-add-respondent"
        >
          <button
            class="btn btn-sqare btn-outline-secondary btn-add-respondent btn-sm"
            type="button"
            @click="addRespondentFromDetail"
          >
            <span class="sign-in-text">{{ $t('addRespondent') }}</span>
          </button>
        </div>
      </template>
    </detail-view>
    <vue-good-table
      :ref="tableReference"
      :is-loading="isLoading"
      :total-rows="totalRecords"
      :rows="patients"
      :columns="columns"
      :row-style-class="rowStyleClassFn"
      :search-options="{
        enabled: true,
        externalQuery: search
      }"
      :max-height="maxHeight"
      :fixed-header="true"
      :pagination-options="{
        enabled: true
      }"
      mode="remote"
      style-class="vgt-table"
      @on-sort-change="sortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-search="searchTermChange"
      @on-cell-click="openDetail"
    >
      <div
        v-show="isLoading"
        slot="loadingContent"
      >
        <LoadingSpinner />
      </div>
      <div
        v-show="!isLoading"
        slot="emptystate"
      >
        {{ emptyMessage }}
      </div>
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.label == 'initials'" />
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <ClinicianPatientsListTableItem
          :props="props"
          :search="search"
          @toPatientDetail="saveTableParams"
        />
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <PaginationFooter
          v-show="paginationEnabled"
          :total="props.total"
          :server-params="serverParams"
          :per-page-changed="props.perPageChanged"
          :page-changed="onPageChange"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import DetailView from '@/components/common/DetailView'
import { mapGetters, mapMutations } from 'vuex'
import Overlay from '@/components/clinician/Overlay'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { Helpers } from '@/mixins/Helpers'
import { PatientsList } from '@/mixins/TableColumnsHelper'
import ClinicianCustomizeMeasure from '@/components/clinician/measures/ClinicianCustomizeMeasure'
import { PatientHelper } from '@/mixins/PatientHelper'
import ClinicianPatientsListTableItem from '@/components/clinician/patients/ClinicianPatientsListTableItem'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import LastOwlSessionDetail from '@/components/common/LastOwlSessionDetail'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'
import ClinicianRespondentFormWrapper from '@/components/clinician/patients/ClinicianRespondentFormWrapper'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import { RemoteTable } from '@/mixins/remoteTables/RemoteTable'
import PaginationFooter from '@/components/common/PaginationFooter'
import { SET_PATIENTS_TABLE_PARAMS } from '@/store/modules/user/constants.js'

export default {
  name: 'ClinicianPatientsList',
  components: {
    VueGoodTable,
    PaginationFooter,
    LastOwlSessionDetail,
    LoadingSpinner,
    DetailView,
    Overlay,
    ClinicianPatientsListTableItem,
    ClinicianRespondentFormWrapper,
    ClinicianCustomizeMeasure
  },
  mixins: [
    Helpers,
    DateTimeHelper,
    PatientHelper,
    PatientsList,
    LastSessionDetail,
    UserManagementComponentMixin,
    RemoteTable
  ],
  props: {
    search: {
      type: String
    },
    fromPatientLayout: {},
    action: {
      type: String,
      default: 'GET_PATIENTS'
    }
  },
  data () {
    return {
      tableReference: 'table',
      tableMaxHeight: 135,
      defaultSortOrder: 'desc',
      defaultSortField: 'latest_completed_session_date',
      rowActive: null,
      addRespondentToPatient: null,
      searchTerm: ''
    }
  },
  computed: {
    ...mapGetters({
      patients: 'getPatients',
      backRoute: 'getSinglePatientReferrerRoute',
      tableParams: 'getPatientTableParams',
      customMeasure: 'getCustomMeasure',
      uiSettings: 'getUiSettings'
    }),
    routematch () {
      const allowedRoutes = ['ClinicianAddPatient', 'ClinicianEditPatient', 'ClinicianAddMeasureToPatient', 'ClinicianAddProgramToPatient']
      return allowedRoutes.includes(this.$route.name)
    },
    showArrowBack () {
      return this.$route.query.justAdded &&
        (this.$route.name === 'ClinicianAddMeasureToPatient' ||
        this.$route.name === 'ClinicianAddPatientToProgram')
    },
    emptyMessage () {
      return this.action === 'GET_PATIENTS_NEW_RESULTS'
        ? this.$t('noPatientCompletedMeasures')
        : (this.totalRecords && this.patients && !this.patients.length) || (this.searchTerm && this.totalRecords === 0)
            ? this.$t('noPatientsFound')
            : this.$t('noPatientsAssigned')
    },
    width () {
      return this.customMeasure ? window.innerWidth > 800 ? 800 : window.innerWidth : null
    },
    tabs () {
      return [{
        label: 'Patients',
        href: 'ClinicianPatientsList'
      }]
    }
  },
  watch: {
    action (n) {
      this.$store.dispatch('CANCEL_AXIOS_REQUEST', { requestIdentifier: 'patientListData' })
      this.loadItems()
    }
  },
  created () {
    this.$store.dispatch('CANCEL_AXIOS_REQUEST', { requestIdentifier: 'patientListData' })
    this.$store.commit('patients/SET_PATIENT_LIST', [])

    if (this.fromPatientLayout && this.$route.name !== 'ClinicianPatientsList' && this.tableParams) {
      this.serverParams = { ...this.tableParams }
    }

    this.loadItems()
    componentRefreshSingleton.addComponentToRefresh(this.$options.name, this)

    if (this.$route.query.search) {
      // intercept search query passed back from detail page, populate search box, and pre-filter results
      this.searchTermChange({ searchTerm: this.$getDecodedId(this.$route.query.search) })
    }
  },
  beforeDestroy () {
    componentRefreshSingleton.removeComponentToRefresh(this.$options.name)
  },
  destroyed () {
    this.closeLastSessionDetail()
  },
  methods: {
    ...mapMutations({
      setTableParams: SET_PATIENTS_TABLE_PARAMS
    }),
    searchTermChange (params) {
      this.searchTerm = params.searchTerm
      this.$store.dispatch('CANCEL_AXIOS_REQUEST', { requestIdentifier: 'patientListData' })
      this.onSearchTermChange(params)
    },
    sortChange (params) {
      this.onSortChange(params)
      this.closeLastSessionDetail()
    },
    saveTableParams (id) {
      this.serverParams.scrollToId = id
      this.setTableParams(this.serverParams)
    },
    openDetail (params) {
      const classValue = this.$getClassValueFromEvent(params.event)
      // prevent open detail on dots menu and go to patient links
      if (!classValue.includes('fa-ellipsis-v') && !classValue.includes('go-to-patient') && !classValue.includes('dropdown-item')) {
        params.row.initials = `${params.row.first_name[0]}${params.row.last_name[0]}`
        this.openLastSessionDetail(params.row)
        this.rowActive = params.row.access_control_id
      }
    },
    rowStyleClass (row) {
      return this.rowActive === row.access_control_id && this.detailViewOpened ? 'row-active' : ''
    },
    conditionsField (patient) {
      return patient.conditions ? patient.conditions.join() : ''
    },
    closeOverlay () {
      this.$router.push({ name: 'ClinicianPatientsList' })
      this.$store.dispatch('SET_CUSTOM_MEASURE', null)
      this.addRespondentToPatient = null
    },
    goBack () {
      const patientId = this.$getDecodedId(this.$route.params.patientId)
      return this.$router.push({ name: 'ClinicianEditPatient', params: { patientId: patientId } })
    },
    rowStyleClassFn (row) {
      const highlightClass = this.rowActive === row.access_control_id && this.detailViewOpened ? 'row-active' : ''
      return `${row.status} ${highlightClass}`
    },
    changeFilter (filter) {
      const comingFromAll = !this.uiSettings.patientsList || this.uiSettings.patientsList.view === 'all'
      this.$store.dispatch('UPDATE_PATIENT_LIST_VIEW', filter).then(() => {
        this.$store.dispatch('UPDATE_UI_SETTINGS', this.uiSettings)
        if (!comingFromAll) {
          this.loadItems()
        }
      })
    },
    refreshList () {
      this.loadItems()
    },
    finished () {
      if (this.lastSessionDetail) {
        this.fetchTeamLastSession({ ...this.lastSessionDetail })
      }
      this.addRespondentToPatient = null
    },
    loadedItemsEvent () {
      if (this.fromPatientLayout && this.$route.name === this.backRoute) {
        this.$nextTick().then(() => {
          const id = `#${this.serverParams.scrollToId}`
          if (document.querySelector(id)) {
            this.$scrollTo(id, 500, { container: '.clinician-patients-list .vgt-responsive', offset: -55 })
          }
        })
      }
      this.closeLastSessionDetail()
    },
    refreshView () {
      this.loadItems()
    }
  }
}
</script>
