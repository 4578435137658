<template>
  <div class="last-owl-session-detail-container">
    <div class="last-owl-session-detail-view-sub-header">
      <div>
        <router-link
          class="go-to-patient"
          :to="{ name: 'ClinicianPatient', params: {ccaId: $getEncodedId(teamMember.client_clinic_assignment_id), ccauId: $getEncodedId(teamMember.client_clinic_assignment_user_id)} }"
        >
          {{ teamMember.name }}
        </router-link>,
        {{ teamMember.patient_relation }}
      </div>
      <SignInPatientButton
        :key="teamMember.user_id"
        :user="teamMember"
      />
    </div>
    <div
      v-if="!teamMemberSessions.length"
      class="no-records"
    >
      {{ $t('noSessionsDue') }}
    </div>
    <div
      v-for="(session, i) in teamMemberSessions"
      v-else
      :key="i"
      class="last-owl-session-detail-view-content"
    >
      <h5 class="due">
        {{ teamMember.access_control_program }}
        <span v-if="session && session.date">{{ dueOrCompleted(session.status) }} {{ date(session) }} </span> <span v-if="session.access_control_program"> <span class="for">for</span> {{ session.access_control_program.program.full_name }}</span>
      </h5>
      <div class="row">
        <div class="col-12 latest-session-measure-detail color-navy">
          <div
            v-for="(measure, index) in session.measures"
            :key="index"
            class="measure-element"
          >
            <MeasureStatusIcon
              :class="{skipped: !!measure.skipped_amount}"
              :session="measure"
            />
            <div class="accions">
              <span
                v-tooltip="{content: measure.full_name, classes: [&quot;measure-name&quot;] }"
                :class="{'is-link': measure.client_measure_status === CLIENT_MEASURE_STATUS_COMPLETED}"
                @click="openMeasurePage(measure)"
              >
                {{ measure.abbreviation }}
              </span>
              <span v-if="measure.status === SESSION_STATUS_INCOMPLETE">(<a @click="openEditMeasure(measure)">{{ $t('completeItems', {count: measure.skipped_amount}) }}</a>)
              </span>
            </div>
            <div class="clearfix" />
          </div>
        </div>
      </div>
    </div>
    <div class="line-break" />
  </div>
</template>

<script>
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { Helpers } from '@/mixins/Helpers'
import { RespondentHelper } from '@/mixins/RespondentHelper'
import MeasureStatusIcon from '@/components/common/MeasureStatusIcon'
import SignInPatientButton from '@/components/clinician/patients/SignInPatientButton'
import { SESSION_STATUS_COMPLETED, SESSION_STATUS_INCOMPLETE, SESSION_STATUS_NO_SESSIONS } from '@/data/lastSessionStatus'
import { CLIENT_MEASURE_STATUS_COMPLETED } from '@/data/clientMeasureStatus'

export default {
  name: 'TeamMemberDetail',
  components: { MeasureStatusIcon, SignInPatientButton },
  mixins: [DateTimeHelper, RespondentHelper, Helpers],
  props: ['teamMember'],
  data () {
    return {
      SESSION_STATUS_INCOMPLETE: SESSION_STATUS_INCOMPLETE,
      CLIENT_MEASURE_STATUS_COMPLETED: CLIENT_MEASURE_STATUS_COMPLETED
    }
  },
  computed: {
    teamMemberSessions () {
      return this.teamMember.pending_sessions && this.teamMember.pending_sessions.length ? this.teamMember.pending_sessions : this.teamMember.latest_complete && this.teamMember.latest_complete.status !== SESSION_STATUS_NO_SESSIONS ? [this.teamMember.latest_complete] : []
    }
  },
  methods: {
    date (session) {
      const date = parseInt(session.status) === SESSION_STATUS_COMPLETED ? session.completed_date : session.date
      return this.$toLocal(date, 'MMM DD')
    },
    dueOrCompleted (status) {
      return parseInt(status) === SESSION_STATUS_COMPLETED ? this.$t('completed') : this.$t('due')
    },
    openEditMeasure (measure) {
      this.$emit('openEditMeasure', { measure, patient: this.teamMember })
    },
    openMeasurePage (measure) {
      const ccauId = this.$getEncodedId(this.teamMember.client_clinic_assignment_user_id)
      const ccaId = this.$getEncodedId(this.teamMember.client_clinic_assignment_id)
      const measureId = this.$getEncodedId(measure.client_measure_id)
      this.$router.push({ name: 'SessionDetail', params: { ccauId, ccaId, measureId } })
    }
  }
}
</script>
