<template>
  <div class="clinician-customize-measure">
    <single-measure
      :custom-measure-id="measureId"
      @customizationFinished="finish"
    />
  </div>
</template>
<style>

</style>
<script>
import { mapGetters } from 'vuex'
import SingleMeasure from '@/components/measures/SingleMeasure'

export default {
  name: 'ClinicianCustomizeMeasure',
  components: {
    SingleMeasure
  },
  props: ['measureId'],
  computed: {
    ...mapGetters({
      singleMeasure: 'activeMeasure'
    })
  },
  created () {
    const loader = this.$loading.show()
    this.$store.dispatch('GET_SINGLE_MEASURE', { id: this.measureId, pageId: 0 })
      .catch(e => this.$handleApiError(e))
      .finally(() => loader.hide())
  },
  beforeDestroy () {
    this.$store.dispatch('SET_CUSTOM_MEASURE', null).catch(e => this.$handleApiError(e))
  },
  methods: {
    finish () {
      this.$emit('finish')
    }
  }
}
</script>
<style lang="scss">
.clinician-customize-measure {
  .single-measure {
    .outer-flex-container{
      .options-container {
        background: #f1f5f8
      }
    }
  }
}
</style>
