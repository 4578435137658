import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
const RELATION_PATIENT = 1
export const RespondentHelper = {
  mixins: [Helpers],
  data () {
    return {
    }
  },
  methods: {
    $getRelationIdPatient () {
      return RELATION_PATIENT
    },
    $removeRespondent (ccauId) {
      const promptOptions = {
        title: 'Are you sure you want to remove the respondent from the patient’s team?',
        message: 'Removing the respondent will disable any measures assigned to them for this patient.',
        classname: 'delete',
        okButton: this.$t('yesRemove'),
        cancelButton: this.$t('cancel')
      }
      return new Promise((resolve, reject) => {
        this.$promptBeforeAction(promptOptions, () => {
          this.$store.dispatch('REMOVE_RESPONDENT_FROM_TEAM', ccauId).then((result) => {
            resolve(result)
          }).catch((e) => {
            reject(e)
          })
        })
      })
    },
    isPatientRelation (relation) {
      return Number(relation) === Number(RELATION_PATIENT)
    }
  },
  computed: {
    ...mapGetters({})
  }
}
