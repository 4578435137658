import { SEND_REMINDER, SEND_INVITATION } from '@/store/modules/respondents/constants'
import { RESET_RESPONDENT_USER_PASSWORD } from '@/store/modules/security/constants'
import { MUTATION_SET_MODAL_OPENED, MUTATION_SET_MODAL_TEMPLATE, MUTATION_SET_MODAL_CONFIG } from '@/store/modules/applicationState/constants'
import { mapMutations, mapGetters } from 'vuex'

export const RespondentAccountActions = {
  data () {
    return {
      modalData: {
        hideCloseIcon: true,
        customClass: 'owl-message-modal'
      }
    }
  },
  methods: {
    ...mapMutations({
      setModalOpen: MUTATION_SET_MODAL_OPENED,
      setDeleteModalTemplate: MUTATION_SET_MODAL_TEMPLATE,
      customModalConfig: MUTATION_SET_MODAL_CONFIG
    }),
    openModal () {
      this.modalData.user = this.user
      this.setModalOpen(true)
      this.setDeleteModalTemplate('SendMessageToPatient')
      this.customModalConfig(this.modalData)
    },
    sendInvite () {
      const name = this.user.full_name || this.user.name
      this.modalData.title = this.$t('sendMessageModalTitle', { name: name, action: this.$t('invitation') })
      this.modalData.action = SEND_INVITATION
      this.modalData.successMesage = this.$t('invitationSuccess', { name })
      this.modalData.addContactValue = true
      this.openModal()
    },
    sendReminder () {
      const name = this.user.full_name || this.user.name
      this.modalData.title = this.$t('sendMessageModalTitle', { name: name, action: this.$t('reminder') })
      this.modalData.action = SEND_REMINDER
      this.modalData.successMesage = this.$t('reminderSuccess', { name })
      this.modalData.addContactValue = true
      this.openModal()
    },
    resetPassword () {
      const name = this.user.full_name || this.user.name
      this.modalData.title = this.$t('sendMessageModalTitle', { name: name, action: this.$t('passwordReset').toLowerCase() })
      this.modalData.action = RESET_RESPONDENT_USER_PASSWORD
      this.modalData.successMesage = this.$t('resetPasswordSuccess', { name })
      this.openModal()
    }
  },
  computed: {
    ...mapGetters({
      modalOpened: 'getCustomModalIsOpened'
    })
  },
  destroyed () {
    this.customModalConfig({})
    this.setModalOpen(false)
    this.setDeleteModalTemplate(null)
  }
}
