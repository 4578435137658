class ComponentRefreshSingleton {
  constructor () {
    this.componentsToRefresh = []
  }

  addComponentToRefresh (key, component) {
    this.componentsToRefresh.push({ name: key, component: component })
  }

  removeComponentToRefresh (key) {
    const index = this.componentsToRefresh.findIndex((i) => i.name === key)
    if (index) {
      this.componentsToRefresh.splice(index, 1)
    }
  }

  refreshComponents () {
    for (let i = 0, len = this.componentsToRefresh.length; i < len; i++) {
      const item = this.componentsToRefresh[i]
      if (item && item.component) {
        item.component.refreshView()
      }
    }
    return true
  }

  refreshComponentViewByName (key, parameters) {
    const item = this.componentsToRefresh.find((i) => i.name === key)
    if (item && item.component) {
      item.component.refreshView(parameters)
    }
  }
}

export const componentRefreshSingleton = new ComponentRefreshSingleton()
