<template>
  <div class="detail-view">
    <div
      v-if="showHeader"
      class="detail-header"
    >
      <slot name="title">
        <span>{{ title }}</span>
      </slot>
      <i
        class="fal fa-times"
        @click="$emit('close')"
      />
    </div>
    <div class="slot-content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailView',
  props: {
    title: String, // eslint-disable-line
    bodyClass: String, // eslint-disable-line
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasBodyClass () {
      return !!this.bodyClass
    }
  },
  created () {
    if (this.hasBodyClass) {
      document.body.classList.add(this.bodyClass)
    }
  },
  destroyed () {
    if (this.hasBodyClass) {
      document.body.classList.remove(this.bodyClass)
    }
  }
}
</script>
<style lang="scss" scoped>
.detail-view {
    position: fixed;
    right: 0;
    top: 78px;
    width: calc(100% - 925px);
    height: 100%;
    overflow: auto;
    .detail-header {
      // width: calc(100% - 925px);
      // position: fixed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 15px;
      // margin-top: 4px;
      background: #f1f5f8;
      // z-index: 1;
      i {
        padding: 7px;
        margin-right: 7px;
        &:hover {
          cursor: pointer;
        }
      }
    }
}
.overlay-opened {
  .detail-view {
    overflow: visible;
    z-index: 11;
  }
}
</style>
