<template>
  <div class="clinician-respondents-list">
    <clinicianLayoutSubHeader />
    <vue-good-table
      :ref="tableReference"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="respondents"
      :total-rows="totalRecords"
      style-class="vgt-table"
      :max-height="maxHeight"
      :fixed-header="true"
      :pagination-options="{
        enabled: true
      }"
      :row-style-class="rowStyleClassFn"
      :search-options="{
        enabled: true,
        externalQuery: search
      }"
      :sort-options="{
        enabled: true
      }"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-search="onSearchTermChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div v-if="props.column.label == $t('respondentsTable.respondent')">
          {{ props.row.name }}
        </div>
        <div v-else-if="props.column.label == $t('respondentsTable.contact')">
          <div
            v-for="(contact, index) in props.row.contact"
            :key="index"
          >
            {{ contact }}
          </div>
        </div>
        <div v-else-if="props.column.label == $t('respondentsTable.lastActivity')">
          <div v-if="props.row.latest_activity_code">
            <span class="last-activity-date">
              {{ $toLocal(props.row.latest_activity_datetime, 'MM/DD/YYYY') }};
            </span>
            <br>
            <span class="last-activity-date">{{ $t('latest_activity_codes.value_' + props.row.latest_activity_code) }}</span>
          </div>
        </div>
        <div v-else-if="props.column.label == $t('respondentsTable.relationship')">
          <div
            v-for="patient in props.row.patients"
            :key="patient.ccauId"
          >
            <span v-if="patient.patient_relation">{{ $t('patient_relations.relation_id_' + patient.patient_relation) }}</span>
          </div>
        </div>
        <div v-else-if="props.column.label == $t('respondentsTable.patient')">
          <div
            v-for="patient in props.row.patients"
            :key="patient.ccauId"
          >
            <router-link
              class="name go-to-patient"
              :to="{ name: 'ClinicianPatient', params: {ccaId: $getEncodedId(patient.ccaId), ccauId: $getEncodedId(patient.respondent_ccauId)} }"
            >
              {{ patient.full_name }}
            </router-link>
          </div>
        </div>
        <div v-else-if="props.column.label == $t('respondentsTable.mrn')">
          <div
            v-for="patient in props.row.patients"
            :key="patient.ccauId"
          >
            {{ patient.mrn }}
          </div>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
      <div
        v-show="isLoading"
        slot="loadingContent"
      >
        <LoadingSpinner />
      </div>
      <div
        v-show="!isLoading"
        slot="emptystate"
      >
        {{ $t('noRespondentsFound') }}
      </div>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <PaginationFooter
          v-show="paginationEnabled"
          :total="props.total"
          :server-params="serverParams"
          :per-page-changed="props.perPageChanged"
          :page-changed="onPageChange"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapGetters } from 'vuex'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import ClinicianLayoutSubHeader from '@/components/common/ClinicianLayoutSubHeader'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { Helpers } from '@/mixins/Helpers'
import { RespondentList } from '@/mixins/TableColumnsHelper'
import { RemoteTable } from '@/mixins/remoteTables/RemoteTable'
import PaginationFooter from '@/components/common/PaginationFooter'
import { GET_RESPONDENTS } from '@/store/modules/respondents/constants.js'

export default {
  name: 'ClinicianRespondentsList',
  components: {
    VueGoodTable,
    LoadingSpinner,
    PaginationFooter,
    ClinicianLayoutSubHeader
  },
  mixins: [RespondentList, DateTimeHelper, Helpers, RemoteTable],
  props: ['search'],
  data () {
    return {
      tableReference: 'clinicianRespondentsList',
      defaultSortOrder: 'asc',
      defaultSortField: 'name',
      tableMaxHeight: 170,
      action: GET_RESPONDENTS
    }
  },
  computed: {
    ...mapGetters({
      respondents: 'getRespondents'
    })
  },
  created () {
    document.body.classList.add('wider')
    this.refreshList()
  },
  beforeDestroy () {
    document.body.classList.remove('wider')
  },
  methods: {
    rowStyleClassFn (row) {
      return `${row.status}`
    },
    refreshList () {
      this.loadItems()
    }
  }
}
</script>
<style lang="scss">
.body-clinician-layout {
  .clinician-respondents-list {
    thead {
      th {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        color: #0a3560;
        ::after {
          margin-left: 0px !important;
        }
      }
    }

    tbody {
      tr {
        &:hover {
          cursor: default;
        }
        td {
          font-size: 15px;
          font-weight: 300;
          color: #0a3560;
          &.name {
            font-weight: 500;
          }
          a {
            color: #0a3560;
          }
          &.contact {
            div > div {
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}
</style>
