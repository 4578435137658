<template>
  <div
    v-if="userInEdition"
    class="settings-users-extra-header"
  >
    <div class="settings-users-extra-header__item">
      {{ $t('username') }}
      <span>{{ usernameOrStatus }}</span>
    </div>
    <div class="settings-users-extra-header__item">
      {{ $t('owlId') }}
      <span>{{ userInEdition.id }}</span>
    </div>
  </div>
</template>

<script>
import { SettingsUserExtraHeaderMixin } from '@/mixins/UserForms/SettingsUserExtraHeaderMixin'
import {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_NOT_INVITED,
  STATUS_INVITATION_SENT
} from '@/data/userStatusValues'

export default {
  name: 'SettingsUserExtraHeader',
  mixins: [SettingsUserExtraHeaderMixin],
  computed: {
    usernameOrStatus () {
      const status = this.userInEdition.account_information.need_setup
        ? this.userInEdition.account_information.status
        : 'NA'

      switch (status) {
        case STATUS_INACTIVE:
          return this.userInEdition.account_information.invitation_sent ? this.$t('inviteSent') : this.$t('notInvited')

        case STATUS_NOT_INVITED:
          return this.$t('notInvited')

        case STATUS_INVITATION_SENT:
          return this.$t('inviteSent')

        case STATUS_ACTIVE:
        default:
          return this.userInEdition.account_information.username
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .settings-users-extra-header {
    display: flex;
  }
  .settings-users-extra-header__item {
    padding-right: 15px;
    font-size: 12px;
    text-align: right;
    text-transform: uppercase;

    span {
      display: block;
      text-transform: capitalize;
      font-size: 16px;
    }
  }
</style>
