<template>
  <PatientTableItem
    v-if="props.column.label == $t('patientsTable.patient')"
    :first="props.row.first_name[0]"
    :second="props.row.last_name[0]"
    :alert-count="props.row.active_alert_count"
    :status="props.row.status"
    :is-inactive="props.row.status == 1"
    :name="props.row.name"
    :cca-id="props.row.client_clinic_assignment_id"
    :ccau-id="props.row.client_clinic_assignment_user_id"
    :age="getDifferenceInYearsBetweenDateStringAndTodayDate(props.row.date_of_birth)"
    :gender="props.row.gender"
    :owl-id="props.row.access_control_id"
    :item-id="`patientlist${props.row.client_clinic_assignment_user_id}${props.row.client_clinic_assignment_id}`"
    :mrn="props.row.mrn"
    :search="search"
    @toPatientDetail="goToPatientDetail"
  />
  <span
    v-else-if="props.column.label == $t('patientsTable.clinicians')"
    class="patient-clinicians"
  >
    <template v-if="props.row.clinicians && props.row.clinicians.length">
      <span
        v-for="(clinician, index) in props.row.clinicians"
        :key="'clinician'+index"
      >
        {{ clinician }}<br>
      </span>
    </template>
    <span v-if="props.row.teams && props.row.teams.length">
      <span
        v-for="(team, index) in props.row.teams"
        :key="'teams'+index"
      >
        {{ team }}
      </span>
    </span>
  </span>
  <span v-else-if="props.column.label == $t('patientsTable.nextSessionDue')">
    <NextSessionDueItem
      :data="props.row"
      source="patientsList"
    />
  </span>
  <span v-else-if="props.column.label == $t('patientsTable.lastSessionCompleted')">
    <LatestCompletedSessionItem
      :data="props.row"
      source="patientsList"
    />
  </span>
  <span v-else-if="props.column.label == $t('patientsTable.nextVisit')">
    {{ $toLocal(props.row.next_visit, 'MMM DD, YYYY') }}
  </span>
</template>

<script>
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import PatientTableItem from '@/components/common/PatientTableItem'
import LatestCompletedSessionItem from '@/components/common/LatestCompletedSessionItem'
import NextSessionDueItem from '@/components/common/NextSessionDueItem'

export default {
  name: 'ClinicianPatientsListTableItem',
  components: {
    PatientTableItem,
    LatestCompletedSessionItem,
    NextSessionDueItem
  },
  mixins: [DateTimeHelper],
  props: {
    props: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: String,
      default: ''
    }
  },
  methods: {
    goToPatientDetail (params) {
      this.$emit('toPatientDetail', params)
    }
  }
}
</script>
