import { Helpers } from '@/mixins/Helpers'
import { setScrollBarWidth } from '@/mixins/ScrollBarWidthHelper'
export const RemoteTable = {
  mixins: [Helpers],
  data () {
    return {
      setInitialSort: false,
      doneTypingInterval: 500,
      isLoading: true,
      rows: [],
      totalRecords: 0,
      serverParams: {
        customFilters: {},
        columnFilters: {},
        searchTerm: null,
        sort: {
          field: null,
          type: null
        },
        page: 1,
        perPage: 50
      }
    }
  },
  methods: {
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    getSearchTermArray (searchTerm) {
      const trimmed = searchTerm ? searchTerm.replace(/[^a-zA-Z0-9'\- ]/g, ' ') : ''
      return trimmed.split(' ').filter(t => t)
    },
    onSearchTermChange (params) {
      // only trigger search if length is >= 2 (or search clearedd)
      if (!params.searchTerm || params.searchTerm.length > 1) {
        params.searchTerm = this.getSearchTermArray(params.searchTerm)
        this.updateParams(params)
        this.loadItems(true)
      }
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange (params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange (params) {
      this.updateParams({
        page: 1,
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.loadItems()
    },
    onColumnFilter (params) {
      this.updateParams(params)
      this.loadItems()
    },
    onChangeCustomFilter (params) {
      this.updateParams(params)
      this.loadItems()
    },
    loadItems (resetPage) {
      this.isLoading = true
      this.$store.dispatch('CANCEL_AXIOS_REQUEST', { requestIdentifier: 'loadItems' })
      this.getFromServer(resetPage).then(response => {
        setScrollBarWidth()
        if (this.$route.name === 'AnalyticsOutcomesExplorer') {
          this.totalRecords = response ? response.data.totalRecords || response.data.count : 0
        } else {
          this.totalRecords = response && response.data && response.data.totalRecords ? response.data.totalRecords : 0
        }
        if (this.setInitialSort) {
          this.setInitialSortWorkAround()
          this.setInitialSort = false
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    getFromServer (resetPage = false) {
      const params = this.getServerParams()
      if (resetPage) {
        params.page = 1
        params.offset = 0
        this.serverParams.page = 1
        this.serverParams.offset = 0
      }
      return this.$store.dispatch(this.action, params)
    },
    getServerParams () {
      // Set Default search order
      if (this.serverParams.sort.field === null) {
        this.serverParams.sort.field = this.defaultSortField
        this.serverParams.sort.type = this.defaultSortOrder
        this.setInitialSort = true
      }
      if (this.customParams) {
        Object.assign(this.serverParams, this.customParams)
      }
      if (this.beforeLoadItems && typeof this.beforeLoadItems === 'function') {
        this.beforeLoadItems()
      }

      return this.noStandarRemoteTable ? this.convertToNoStandar(this.serverParams) : this.serverParams
    },
    setInitialSortWorkAround () {
      if (this.tableReference && this.$refs[this.tableReference] && this.defaultSortOrder && this.defaultSortField) {
        const headerRef = this.$refs[this.tableReference].fixedHeader ? 'table-header-secondary' : 'table-header-primary'
        this.$refs[this.tableReference].$refs[headerRef].sorts = [{
          type: this.defaultSortOrder,
          field: this.defaultSortField
        }]
      }
    },
    triggerLoadedItemsEvent () {
      if (typeof this.loadedItemsEvent === 'function') {
        this.loadedItemsEvent()
      }
    },
    convertToNoStandar (params) {
      const noStandardParams = this.copyObj(params)
      // Change order
      noStandardParams.order_by = {
        direction: params.sort.type,
        column: params.sort.field
      }
      // Change per page
      noStandardParams.limit = params.perPage
      // Set offset
      noStandardParams.offset = (params.page - 1) * params.perPage

      delete noStandardParams.sort
      delete noStandardParams.perPage
      delete noStandardParams.page

      return noStandardParams
    }
  },
  computed: {
    paginationEnabled () {
      return this.totalRecords > this.serverParams.perPage && !this.hidePagination
    },
    maxHeight () {
      const h = this.tableMaxHeight ? this.tableMaxHeight : 0
      return `calc(100vh - ${this.paginationEnabled ? h + 76 : h}px)`
    }
  }
}
