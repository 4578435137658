<template>
  <label :class="classList">
    <input
      type="checkbox"
      class="switch-input"
      :disabled="!enabled"
      :value="value"
      :checked="isChecked"
      @change="handleChange"
    >
    <template v-if="isOn">
      <span
        class="switch-label"
        :data-on="on"
        :data-off="off"
      />
    </template>
    <template v-else>
      <span class="switch-label" />
    </template>
    <span class="switch-handle" />
  </label>
</template>

<script>
export default {
  name: 'CSwitch',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    /* eslint-disable vue/require-prop-types */
    enabled: {
      default: true
    },
    value: {
      default: true
    },
    uncheckedValue: {
      default: false
    },
    checked: {
      default: false
    },
    type: {
      type: String,
      default: 'default'
    },
    variant: {
      type: String,
      default: ''
    },
    pill: {
      type: Boolean,
      default: false
    },
    on: {
      type: String,
      default: null
    },
    off: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    }
    /* eslint-enable vue/require-prop-types */
  },
  computed: {
    classList () {
      return [
        'switch',
        this.disabledClass,
        this.switchType,
        this.switchVariant,
        this.switchPill,
        this.switchSize
      ]
    },
    disabledClass () {
      return this.enabled ? '' : 'disabled'
    },
    switchType () {
      return this.type ? `switch-${this.type}` : 'switch-default'
    },
    switchVariant () {
      return this.variant ? `switch-${this.variant}` : 'switch-secondary'
    },
    switchPill () {
      return !this.pill ? null : 'switch-pill'
    },
    switchSize () {
      return this.size ? `switch-${this.size}` : ''
    },
    isChecked () {
      return this.checked === this.value
    },
    isOn () {
      return !this.on ? null : true
    }
  },
  methods: {
    handleChange ({ target: { checked } }) {
      this.$emit('change', checked ? this.value : this.uncheckedValue)
    }
  }
}
</script>
