<template>
  <nav
    v-show="showPagination"
    class="vgt-custom-pagination"
  >
    <ul class="pagination">
      <li
        class="page-item"
        :class="{disabled: !prevIsPossible}"
      >
        <a
          class="page-link"
          href="javascript:void(0)"
          aria-label="Previous"
          @click="prevPage"
        >
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li
        v-for="index in totalPages"
        :key="index"
        class="page-item"
        :class="{active: serverParams.page === index}"
      >
        <a
          class="page-link"
          href="javascript:void(0)"
          @click="goToSpecificPage(index)"
        >{{ index }}</a>
      </li>
      <li
        class="page-item"
        :class="{disabled: !nextIsPossible}"
      >
        <a
          class="page-link"
          href="javascript:void(0)"
          aria-label="Next"
          @click="nextPage"
        >
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'PaginationFooter',
  props: {
    /* eslint-disable */
    pageChanged: {
      type: Function
    },
    perPageChanged: {
      type: Function
    },
    serverParams: {},
    total: {}
  },
  computed: {
    totalPages () {
      const pagesCount = Math.floor(this.total / this.serverParams.perPage)
      return this.total % this.serverParams.perPage === 0 ? pagesCount : pagesCount + 1
    },
    showPagination () {
      return this.totalPages > 1
    },
    prevIsPossible () {
      return this.serverParams.page > 1
    },
    nextIsPossible () {
      return this.serverParams.page < this.totalPages
    }
  },
  created () {
    this.$emit('paginationEnabled', this.showPagination)
  },
  methods: {
    nextPage () {
      if (this.nextIsPossible) {
        this.serverParams.page = this.serverParams.page + 1
        this.triggerPageChange()
      }
    },
    prevPage () {
      if (this.prevIsPossible) {
        this.serverParams.page = this.serverParams.page - 1
        this.triggerPageChange()
      }
    },
    goToSpecificPage (customCurrentPage) {
      this.serverParams.page = customCurrentPage
      this.triggerPageChange()
    },
    triggerPageChange () {
      this.pageChanged({ currentPage: this.serverParams.page })
    }
  }
}
</script>
