<template>
  <span class="clinician-add-respondent-form-item">
    <strong>{{ option.name }}</strong> ({{ option.id }}),
    <span
      v-for="(patient, index) in option.patients"
      :key="patient.mrn"
    >
      {{ getPatientRelation(patient.patient_relation) }} {{ $t('ofPatient') }} <strong>{{ patient.full_name }}</strong> #{{ patient.mrn }}<span v-if="index < (option.patients.length -1)">,</span>
    </span>
    <span
      v-for="(patient, index) in patientsRemoved"
      :key="patient.mrn"
    >
      {{ getPatientRelation(patient.patient_relation) }}  {{ $t('removedFromPatient') }} <strong>{{ patient.full_name }}</strong> #{{ patient.mrn }}<span v-if="index < (option.patients.length -1)">,</span>
    </span>
  </span>
</template>

<script>
import CaregiversData from '@/data/caregivers-data'

export default {
  name: 'SearchExistingRespondentItem',
  props: ['option'], // eslint-disable-line vue/require-prop-types
  data: function () {
    return {
      caregiversData: CaregiversData
    }
  },
  computed: {
    patientsRemoved () {
      if (this.option.patients && this.option.patients.length === 0) {
        return []
      }

      return this.option.patients_removed
    }
  },
  methods: {
    getPatientRelation (relationId) {
      const relation = this.caregiversData.find(r => Number(r.key) === Number(relationId))
      return relation ? relation.value : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.clinician-add-respondent-form-item {
  max-width: 100%;
  white-space: normal;
  line-height: 120%;
  font-family: MuseoSans_300;
  strong {
    font-family: MuseoSans_700;
  }
}
</style>
