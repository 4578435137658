<template>
  <CrudModal
    :class="`${currentItem ? currentItem.buttonText : ''}-form-modal`"
    :title="currentItem.modalTitle"
    :is-update="!!settingEditingInForm"
    :prompt-message="currentItem.prompt"
    :save-text="$t('saveAndClose')"
    :custom-error="customError"
    @close="$emit('close')"
    @save="triggerSave"
  >
    <component
      :is="currentItem.formComponent"
      slot="body"
      ref="formComponent"
      @close="$emit('close')"
      @footerError="e => customError = e"
    />
    <component
      :is="currentItem.extraBottomPanelLeftComponent"
      slot="bottomPanelLeft"
      @deleteItem="deleteItem()"
    />
    <component
      :is="currentItem.extraHeaderComponent"
      slot="extraHeaderInfo"
    />
  </CrudModal>
</template>

<script>
import AccountStatusFilter from '@/components/admin/settings/extra/patientUsers/AccountStatusFilter'
import CrudModal from '@/components/common/CrudModal.vue'
import SettingsPatientUsersBottomPanelLeft from '@/components/admin/settings/extra/patientUsers/SettingsPatientUsersBottomPanelLeft'
import SettingsPatientUsersForm from '@/components/admin/settings/forms/SettingsPatientUsersForm'
import SettingsUsersExtraHeader from '@/components/admin/settings/extra/users/SettingsUsersExtraHeader'
import { mapGetters } from 'vuex'

export default {
  name: 'EditPatientModalWrapper',
  components: {
    AccountStatusFilter,
    CrudModal,
    SettingsPatientUsersBottomPanelLeft,
    SettingsPatientUsersForm,
    SettingsUsersExtraHeader
  },
  data () {
    return {
      customError: ''
    }
  },
  computed: {
    ...mapGetters({
      settingEditingInForm: 'getSettingEditingInForm'
    }),
    currentItem () {
      return {
        title: this.$t('patientUsers'),
        buttonText: this.$t('patient'),
        addCustomAction: 'addPatient',
        prompt: this.$t('patient'),
        modalTitle: this.settingEditingInForm && this.settingEditingInForm.person_information ? `Edit ${this.settingEditingInForm.person_information.full_name}` : `Add ${this.$tc('patient', 1)}`,
        formComponent: 'SettingsPatientUsersForm',
        extraHeaderComponent: 'SettingsUsersExtraHeader',
        extraBottomPanelLeftComponent: 'SettingsPatientUsersBottomPanelLeft',
        topBarExtraActions: 'AccountStatusFilter',
        placeholder: ''
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    triggerSave () {
      this.$refs.formComponent.save()
    }
  }
}
</script>
