<template>
  <div class="clinician-patient-form">
    <LoadingSpinner
      v-if="isLoading"
      class="text-center"
    />
    <clinician-respondent-form
      v-else-if="loaded"
      :key="patient.id"
      ref="respondentForm"
      :action="action"
      :respondent="patient"
      :read-only="patientInformationReadOnly"
      :cca-id="ccaId"
      :patients-by-programs-update="this.$route.name === 'ClinicianHomepage' && action === 'update'"
      :active-assignment="activeAssignment"
      @created="created"
      @submitRespondent="submitRespondent"
      @closeForm="closeForm"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClinicianRespondentForm from '@/components/clinician/patients/ClinicianRespondentForm'
import { Helpers } from '@/mixins/Helpers'
import { PatientHelper } from '@/mixins/PatientHelper'
import LoadingSpinner from '@/components/common/LoadingSpinner'

export default {
  name: 'ClinicianRespondentFormWrapper',
  components: {
    LoadingSpinner,
    ClinicianRespondentForm
  },
  mixins: [Helpers, PatientHelper],
  props: ['ccaId', 'isLoading'],
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    action () {
      return this.$route.params.patientId || this.$route.name === 'ClinicianPatientInformation' ? 'update' : 'create'
    },
    actionText () {
      if (this.$refs.respondentForm.fields.status && this.$refs.respondentForm.fields.status.dirty) {
        return this.patient.status === 'active' ? 'activated' : 'inactivated'
      }
      return this.action === 'update' ? 'updated' : 'added'
    },
    ...mapGetters({
      patient: 'getSinglePatient',
      formDirty: 'getActiveFormDirty',
      activeAssignment: 'getActiveAssignment'
    })
  },
  created () {
    const patientId = this.$route.name === 'ClinicianPatientInformation' ? this.activeAssignment.user_id : this.$route.params.patientId
    const checkFinishLoading = setInterval(() => {
      // Get data if finish loading (false) or property not set
      if (!this.isLoading) {
        clearInterval(checkFinishLoading)
        this.getData(patientId)
      }
    }, 100)
  },
  methods: {
    getData (patientId) {
      this.loaded = false
      this.getPatient(patientId).then(() => {
        this.loaded = true
        if (this.activeAssignment.patient_status === 'inactive' && this.action === 'create') {
          this.$toast.warning({ message: this.$t('willActivatePatient', { action: this.$t('addingRespondent') }) })
        }
      }).catch((e) => {
        this.loaded = true
        this.$handleApiError(e)
      })
    },
    cancel () {
      if (this.$parent.beforeClose) {
        this.$parent.beforeClose()
      }
    },
    created () {
      this.$emit('created')
    },
    closeForm () {
      if (this.$route.name === 'PatientsByProgram') {
        return this.$emit('finished')
      }
      this.$router.push({ name: 'ClinicianPatientMeasures', params: { ccauId: this.$route.params.ccauId, ccaId: this.$route.params.ccaId } })
    },
    submitRespondent (respondent) {
      const wasDirty = this.formDirty
      if (this.action === 'update') {
        return this.executeSubmmitRespondent(respondent, wasDirty)
      }
      this.$reactivateIfInactive(this.activeAssignment).then(() => {
        this.executeSubmmitRespondent(respondent, wasDirty)
      })
    },
    executeSubmmitRespondent (respondent, wasDirty) {
      const action = this.action === 'update' ? 'UPDATE_RESPONDENT' : 'CREATE_RESPONDENT'
      const loader = this.$loading.show()
      const $validator = this.$validator
      this.$store.dispatch(action, respondent)
        .then(response => {
          if (response && response.status !== 200) {
            this.$handleApiError(response)
            Object.keys(response.data.errors).forEach(function (field) {
              $validator.errors.add(field, response.data.errors[field])
            })
          } else {
            this.getRespondentLatestSession(response.data.ccau_id)
            if (this.$route.name !== 'ClinicianPatientInformation') {
              // show toast if changed something
              if (wasDirty) {
                this.$toast.success({ message: this.$t('respondentSumbit', { action: this.actionText }) })
              }
              if (this.$route.name === 'PatientsByProgram') {
                this.$emit('finished')
                this.$router.push({ name: 'ClinicianPatientsByProgramAddMeasureToPatient', params: { ccaId: this.$getEncodedId(response.data.assignment_id), ccauId: this.$getEncodedId(response.data.ccau_id), patientId: this.$getEncodedId(response.data.user_id) } })
                return
              }
              if (this.action !== 'update') {
                this.$emit('created')
              }
              if (this.$route.name === 'ClinicianHomepage') {
                this.$emit('finished')
                this.$router.push({ name: 'ClinicianHomePageAddMeasure', params: { ccauId: this.$getEncodedId(response.data.ccau_id), ccaId: this.$getEncodedId(response.data.assignment_id) } })
                return
              }
              this.$router.push({ name: 'ClinicianPatientAddMeasure', params: { ccauId: this.$getEncodedId(response.data.ccau_id), ccaId: this.$getEncodedId(response.data.assignment_id) } })
            } else {
              this.$toast.success({ message: this.$t('respondentSumbit', { action: this.actionText }) })
            }
            this.$store.dispatch('GET_ASSIGNMENTS', this.ccaId).then(() => {
              this.$store.dispatch('SET_ASSIGNMENT_VIEW', response.data.ccau_id)
            }).catch(() => {
              this.$router.push({ name: 'PageNotFound' })
              this.$refs.respondentForm.submited()
            }).finally(() => {
              loader.hide()
            })
          }
        })
        .catch((e) => {
          this.$handleApiError(e)
        })
        .finally(() => {
          loader.hide()
          if (this.$refs.respondentForm) {
            this.$refs.respondentForm.submited()
            this.$refs.respondentForm.$validator.reset()
          }
        })
    }
  }
}
</script>
