<template>
  <form
    class="settings-patient-user-form"
    @submit.prevent="save"
  >
    <div class="form-row personal-information">
      <div class="form-group col-md-3">
        <label for="patientUserFirstName">{{ $t('settingsPatientUserForm.name_first') }} *</label>
        <input
          id="patientUserFirstName"
          v-model="patientUser.person_information.first_name"
          v-validate="'required'"
          type="text"
          class="form-control"
          name="patientUserFirstName"
          :data-vv-as="$t('settingsPatientUserForm.name_first')"
        >
        <span
          v-show="errors.has('patientUserFirstName')"
          class="error"
        >{{ errors.first('patientUserFirstName') }}</span>
      </div>
      <div class="form-group col-md-3">
        <label for="patientUserMiddleName">{{ $t('settingsPatientUserForm.name_middle') }}</label>
        <input
          id="patientUserMiddleName"
          v-model="patientUser.person_information.middle_name"
          type="text"
          class="form-control"
          name="patientUserMiddleName"
        >
      </div>
      <div class="form-group col-md-3">
        <label for="patientUserLastName">{{ $t('settingsPatientUserForm.name_last') }} *</label>
        <input
          id="patientUserLastName"
          v-model="patientUser.person_information.last_name"
          v-validate="'required'"
          type="text"
          class="form-control"
          name="patientUserLastName"
          :data-vv-as="$t('settingsPatientUserForm.name_last')"
        >
        <span
          v-show="errors.has('patientUserLastName')"
          class="error"
        >{{ errors.first('patientUserLastName') }}</span>
      </div>
    </div>
    <div class="form-row message">
      {{ $t('patientUserFormNotificationsMessage') }}
    </div>
    <div
      v-if="patientUser.contact_information[0]"
      class="form-row emailAdress"
    >
      <div class="form-group">
        <div class="input-group with-switch">
          <label for="patientEmail">{{ $t("settingsPatientUserForm.email") }}</label>
          <label class="notifications">{{ $t("notifications") }}</label>
          <input
            id="patientEmail"
            v-model.trim="patientUser.contact_information[0].value"
            v-validate="'email'"
            type="email"
            :disabled="!emailEnabled"
            class="form-control"
            name="email"
            placeholder="_"
            @keyup="emailNotificationEnabled"
          >
          <div class="switch-container">
            <c-switch
              v-if="emailEnabled"
              v-model="patientUser.contact_information[0].enabled"
              v-validate="{regex: /(.*)\w+/ }"
              type="default"
              :enabled="patientUser.contact_information[0].emailEnabled"
              variant="success"
              :pill="true"
              name="email_notification"
            />
            <span
              v-else
              class="sms-disabled-clinic"
            >{{ $t('emailDisabledClinic') }}</span>
          </div>
        </div>
        <span
          v-show="errors.has('email')"
          class="error"
        >{{ errors.first('email') }}</span>
        <div
          v-if="patientUserEditing.emr_contact_information.length && isEmrEmail"
          class="emr-contact"
        >
          EMR: <span>{{ patientUserEditing.emr_contact_information[0].value }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="patientUser.contact_information[1]"
      class="form-row phoneNumber"
    >
      <div class="form-group">
        <label for="patientPhone">{{ $t("settingsPatientUserForm.phone") }}</label>
        <div class="input-group with-switch">
          <masked-input
            id="patientPhone"
            v-model="patientUser.contact_information[1].value"
            type="tel"
            autocomplete="off"
            class="form-control"
            :disabled="!smsEnabled"
            :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
            :guide="true"
            :show-mask="true"
            name="patientPhone"
            placeholder-char="_"
            @keyup="mobileNotificationEnabled"
          />
          <div class="switch-container">
            <c-switch
              v-if="smsEnabled"
              v-model="patientUser.contact_information[1].enabled"
              v-validate="{regex: /(.*)\w+/ }"
              :enabled="mobile_clean"
              type="default"
              variant="success"
              :pill="true"
              name="mobile_notification"
            />
            <span
              v-else
              class="sms-disabled-clinic"
            >{{ $t('smsDisabledClinic') }}</span>
          </div>
        </div>
        <input
          id="mobile"
          ref="mobile"
          v-model="mobile_clean"
          v-validate="'min:10|max:10'"
          type="hidden"
          name="mobile"
          data-vv-validate-on="input"
          data-vv-as="Phone"
        >
        <span
          v-show="errors.has('mobile')"
          class="error"
        >{{ errors.first('mobile') }}</span>
        <div
          v-if="patientUserEditing.emr_contact_information.length && isEmrPhone"
          class="emr-contact"
        >
          EMR: <span>{{ patientUserEditing.emr_contact_information[1].value | phoneFormat }}</span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import { Helpers } from '@/mixins/Helpers'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { EDITING_SETTING } from '@/store/modules/locations/constants'
import MaskedInput from 'vue-text-mask'
import cSwitch from '@/components/common/Switch'
import {
  SAVE_PATIENT_USER,
  UPDATE_PATIENT_USER
} from '@/store/modules/patientUsers/constants'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SettingsPatientUsersForm',
  components: { MaskedInput, cSwitch },
  filters: {
    phoneFormat: function (phone) {
      const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    }
  },
  mixins: [ErrorsDictionary, Helpers, DateTimeHelper],
  data () {
    return {
      patientUser: {
        account_information: {},
        contact_information: [],
        id: null,
        person_information: {},
        teams_information: []
      }
    }
  },
  computed: {
    ...mapGetters({
      patientUserEditing: 'getSettingEditingInForm'
    }),
    isEmrEmail () {
      return this.patientUserEditing.emr_contact_information[0] && this.patientUserEditing.emr_contact_information[0].value.length && this.patientUserEditing.contact_information[0].value !== this.patientUserEditing.emr_contact_information[0].value
    },
    isEmrPhone () {
      return this.patientUserEditing.emr_contact_information[1] && this.patientUserEditing.emr_contact_information[1].value.length && this.patientUserEditing.contact_information[1].value !== this.patientUserEditing.emr_contact_information[1].value
    },
    isUpdate () {
      return this.patientUserEditing
    },
    toastIndex () {
      return this.isUpdate ? 'patientUserUpdated' : 'patientUserAdded'
    },
    formAction () {
      return this.isUpdate ? UPDATE_PATIENT_USER : SAVE_PATIENT_USER
    },
    computedForm () {
      return Object.assign({}, this.clinicalUser)
    },
    mobile_clean () {
      return this.patientUser.contact_information[1] ? this.getCleanPhone(this.patientUser.contact_information[1].value) : ''
    }
  },
  watch: {
    mobile_clean () {
      this.$nextTick().then(() => {
        this.$validator.validate('mobile')
      })
    },
    computedForm: {
      handler (n, o) {
        const isDirty = Object.keys(n).some(field => typeof n[field] === 'object' ? !this.$arraysEqual(n[field], o[field]) : n[field] !== o[field])
        this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', isDirty)
      },
      deep: true
    }
  },
  created () {
    this.$validator.localize('en', this.errorsDictionary)
    if (this.patientUserEditing) {
      this.patientUser = this.copyObj(this.patientUserEditing)
      this.sortContactInformation()
    }
  },
  destroyed () {
    this.setSettingToEdit(null)
    this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
  },
  methods: {
    ...mapMutations({
      setSettingToEdit: EDITING_SETTING
    }),
    save () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.dispatchSave()
        }
      })
    },
    dispatchSave () {
      this.$genericDispatch(this.formAction, this.patientUser).then(() => {
        this.$toast.success({ message: this.$t(this.toastIndex, { name: `${this.patientUser.person_information.first_name} ${this.patientUser.person_information.last_name}` }) })
        this.closeForm()
      })
    },
    sortContactInformation () {
      const phone = this.getPhoneFromArray(this.patientUser.contact_information) || {}
      phone.enabled = Boolean(parseInt(phone.enabled))
      const email = this.getEmailFromArray(this.patientUser.contact_information) || {}
      email.enabled = Boolean(parseInt(email.enabled))
      this.patientUser.contact_information = [email, phone]
    },
    closeForm () {
      this.setSettingToEdit(null)
      this.$emit('close')
    },
    emailNotificationEnabled () {
      if (!this.patientUser.contact_information[0].value) {
        this.patientUser.contact_information[0].enabled = false
        this.patientUser.contact_information[0].emailEnabled = false
        return
      }
      this.patientUser.contact_information[0].enabled = this.patientUser.contact_information[0].enabled || false
      this.patientUser.contact_information[0].emailEnabled = true
    },
    mobileNotificationEnabled () {
      if (!this.mobile_clean) {
        this.patientUser.contact_information[1].enabled = false
        return
      }
      this.patientUser.contact_information[1].enabled = this.patientUser.contact_information[1].enabled || false
    }
  }
}
</script>
<style lang="scss" scoped>
.error {
  float: none;
  padding-bottom: 0;
}</style>
