<template>
  <div
    v-if="validatedComingFromPatient"
    class="clinician-patients-list"
  >
    <ClinicianLayoutNav
      :show-patient-selector="true"
      :tabs="tabs"
      :show-search="true"
      :init-search="initSearch"
      :placeholder="placeholder"
      @searching="searching"
      @filtersChanged="handlePatientSelectorChange"
    />
    <ClinicianPatientsList
      v-if="type === 'patient'"
      ref="clinicianPatientList"
      :from-patient-layout="fromPatientLayout"
      :search="search"
    />
    <ClinicianRespondentsList
      v-if="type === 'respondent'"
      ref="clinicianRespondentList"
      :from-patient-layout="fromPatientLayout"
      :search="search"
    />
  </div>
</template>

<script>
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import ClinicianLayoutNav from '@/components/common/ClinicianLayoutNav'
import ClinicianPatientsList from '@/components/clinician/patients/ClinicianPatientsList'
import ClinicianRespondentsList from '@/components/clinician/patients/ClinicianRespondentsList'

export default {
  name: 'ClinicianPatientsListWrapper',
  components: {
    ClinicianLayoutNav,
    ClinicianRespondentsList,
    ClinicianPatientsList
  },
  mixins: [LastSessionDetail],
  data () {
    return {
      search: '',
      initSearch: '',
      fromPatientLayout: false,
      validatedComingFromPatient: false,
      tabs: [{
        label: 'Patients',
        href: 'ClinicianPatientsList'
      }, {
        label: 'Respondents',
        href: 'ClinicianRespondentsList'
      }]
    }
  },
  computed: {
    type () {
      return this.$route.matched.some(r => r.name === 'ClinicianPatientsList') ? 'patient' : 'respondent'
    },
    placeholder () {
      return this.$route.name === 'ClinicianPatientsList' ? this.$t('searchPatients') : this.$t('searchRespondents')
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fromPatientLayout = from.matched.some(r => r.name === 'PatientLayout')
      vm.validatedComingFromPatient = true
    })
  },
  methods: {
    searching (search) {
      this.search = search
      this.$emit('searching', search)
    },
    handlePatientSelectorChange () {
      this.closeLastSessionDetail()
      switch (this.type) {
        case 'patient':
          this.$refs.clinicianPatientList.refreshList()
          break
        case 'respondent':
          this.$refs.clinicianRespondentList.refreshList()
          break
      }
    }
  }
}
</script>
