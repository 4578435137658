<template>
  <div
    class="clinician-add-respondent-form"
    :class="{'programs-enabled': settings.programs_enabled, 'programs-disabled': !settings.programs_enabled, 'show-search-respondent': showOnlySearchRespondent}"
  >
    <h3>{{ title }}</h3>
    <div class="row search-respondent">
      <div class="col-12">
        <div class="form-group">
          <label for="search">{{ $t('respondentNameOrId') }}*</label>
          <multiselect
            :options="respondentList"
            :hide-selected="true"
            :show-labels="false"
            :show-pointer="false"
            :show-no-options="false"
            :internal-search="false"
            :show-no-results="false"
            :multiple="false"
            :placeholder="$t('respondentNameOrIdPlaceholder')"
            :preserve-search="currentBrowser.isExplorer"
            track-by="id"
            open-direction="bottom"
            label="name"
            @search-change="searchChange"
            @select="selectRespondent"
          >
            <template
              slot="option"
              slot-scope="props"
            >
              <SearchExistingRespondentItem :option="props.option" />
            </template>
            <template
              v-if="query.length >= 2"
              slot="beforeList"
            >
              <ul class="multiselect__content">
                <li class="multiselect__element">
                  <a
                    class="multiselect__option addAsNew"
                    @click="addAsNew"
                  >{{ $t('addAsNew') }}</a>
                </li>
              </ul>
            </template>
          </multiselect>
        </div>
      </div>
    </div>
    <form
      autocomplete="off"
      @submit.prevent="submitRespondent"
    >
      <div
        v-if="action === 'update'"
        class="row fullWidth"
      >
        <div class="col-5">
          <div class="form-group">
            <label>{{ $t('owlId') }}</label>
            <span class="form-control">{{ patientForm.id }}</span>
          </div>
        </div>
      </div>
      <div class="form-group start-name-group">
        <label for="firstName">{{ $t('respondentFirstName') }}</label>
        <input
          id="firstName"
          ref="firstName"
          v-model="patientForm.first_name"
          v-validate="'required'"
          type="text"
          :disabled="readOnly"
          name="first_name"
          class="form-control"
          :data-vv-as="$t('respondentFirstName')"
        >
        <span
          v-show="errors.has('first_name')"
          class="error"
        >{{ errors.first('first_name') }}</span>
      </div>
      <div class="form-group middle-name-group">
        <label for="middleName">{{ $t('respondentMiddleName') }}</label>
        <input
          id="middleName"
          v-model="patientForm.middle_name"
          v-validate="{regex: /(.*)\w+/ }"
          type="text"
          :disabled="readOnly"
          name="middle_name"
          class="form-control"
          placeholder="-"
        >
        <span
          v-show="errors.has('middle_name')"
          class="error"
        >{{ errors.first('middle_name') }}</span>
      </div>
      <div class="row fullWidth last-name-group">
        <div :class="{'col-5': action === 'update' }">
          <div class="form-group">
            <label for="lastName">{{ $t('respondentLastName') }}</label>
            <input
              id="lastName"
              v-model="patientForm.last_name"
              v-validate="'required'"
              type="text"
              :disabled="readOnly"
              name="last_name"
              class="form-control"
              :data-vv-as="$t('respondentLastName')"
            >
            <span
              v-show="errors.has('last_name')"
              class="error"
            >{{ errors.first('last_name') }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="forceCreation"
        class="searchInfo float-left"
      >
        <span v-if="forceCreationWithExistingRespondent">OWL ID: {{ patientForm.id }}</span>
        <div class="float-right">
          <a @click="searchAgain">{{ $t('searchAgain') }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col-9">
          <div class="form-group start-name-group">
            <label for="patient_relation">{{ $t('respondentPatientRelation') }}</label>
            <select
              id="patient_relation"
              v-model="patientForm.patient_relation"
              v-validate="'required'"
              name="patient_relation"
              :disabled="readOnly"
              class="form-control"
              :data-vv-as="$t('respondentPatientRelation')"
            >
              <option
                v-if="!isPatientInformation"
                value=""
              >
                Select..
              </option>
              <option
                v-for="option in caregiversData"
                :key="option.key"
                :value="option.key"
              >
                {{ option.value }}
              </option>
            </select>
            <span
              v-show="errors.has('patient_relation')"
              class="error"
            >{{ errors.first('patient_relation') }}</span>
          </div>
        </div>
      </div>
      <div class="form-group information">
        <p>{{ $t('respondentContactInfo') }}</p>
        <p class="small">
          {{ $t('respondentWillReceiveNotifications') }}
        </p>
      </div>
      <div class="form-group emailAdress">
        <label for="patientEmail">{{ $t("emailAdress") }}</label>
        <label class="notifications">{{ $t("notifications") }}</label>
        <div class="input-group with-switch">
          <input
            id="patientEmail"
            v-model.trim="patientForm.email"
            v-validate="'email'"
            type="email"
            :disabled="!emailEnabled || readOnly"
            class="form-control"
            name="email"
            placeholder="-"
            @change="setDirty('email')"
          >
          <c-switch
            v-if="emailEnabled"
            v-model="patientForm.email_notification"
            v-validate="{regex: /(.*)\w+/ }"
            :enabled="isEmailNotificationEnabled && !readOnly"
            type="default"
            variant="success"
            :pill="true"
            name="email_notification"
            @change="setDirty('email_notification')"
          />
          <span
            v-else
            class="sms-disabled-clinic"
          >{{ $t('smsDisabledClinic') }}</span>
        </div>
        <span
          v-show="errors.has('email')"
          class="error"
        >{{ errors.first('email') }}</span>
        <div
          v-if="respondent.email_emr && isEmrEmail"
          class="emr-contact"
        >
          EMR: <span>{{ respondent.email_emr }}</span>
        </div>
      </div>
      <div class="form-group phoneNumber">
        <label for="patientPhone">{{ $tc("phoneNumber", 1) }}</label>
        <div class="input-group with-switch">
          <masked-input
            id="patientPhone"
            v-model="patientForm.mobile_number"
            data-vv-validate-on="change"
            type="tel"
            :disabled="readOnly || !smsEnabled"
            autocomplete="off"
            class="form-control start-name-group"
            :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
            :guide="true"
            :show-mask="true"
            name="mobile"
            placeholder-char="_"
            @input="cleanPhone"
          />
          <c-switch
            v-if="smsEnabled"
            v-model="patientForm.mobile_notification"
            v-validate="{regex: /(.*)\w+/ }"
            :enabled="isMobileNotificationEnabled && !readOnly"
            type="default"
            variant="success"
            :pill="true"
            name="mobile_notification"
            @change="setDirty('mobile_notification')"
          />
          <span
            v-else
            class="sms-disabled-clinic"
          >{{ $t('smsDisabledClinic') }}</span>
        </div>
        <input
          id="mobile"
          ref="mobile"
          v-model="mobile_clean"
          v-validate="'min:10|max:10'"
          type="hidden"
          name="mobile"
          data-vv-validate-on="input"
          data-vv-as="Phone"
        >
        <span
          v-show="errors.has('mobile')"
          class="error"
        >{{ errors.first('mobile') }}</span>
        <div
          v-if="respondent.mobile_number_emr && isEmrPhone"
          class="emr-contact"
        >
          EMR: <span>{{ respondent.mobile_number_emr | phoneFormat }}</span>
        </div>
      </div>
      <div
        v-if="showButtons"
        class="buttons"
      >
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </button>
        <button
          type="submit"
          :disabled="disableSubmitButton"
          class="btn btn-secondary"
        >
          {{ saveText }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MaskedInput from 'vue-text-mask'
import cSwitch from '@/components/common/Switch'
import SearchExistingRespondentItem from '@/components/clinician/patients/SearchExistingRespondentItem'
import { Helpers } from '@/mixins/Helpers'
import CaregiversData from '@/data/caregivers-data'
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import Multiselect from 'vue-multiselect'
import { GET_RESPONDENTS } from '@/store/modules/respondents/constants.js'

export default {
  name: 'ClinicianRespondentForm',
  components: {
    MaskedInput,
    SearchExistingRespondentItem,
    cSwitch,
    Multiselect
  },
  filters: {
    phoneFormat: function (phone) {
      const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    }
  },
  mixins: [Helpers, ErrorsDictionary],
  props: {
    /* eslint-disable */
    action: {
      default: 'create'
    },
    ccaId: {},
    respondent: {},
    patientsByProgramsUpdate: {
      default: false
    },
    activeAssignment: {},
    readOnly: {
      default: true
    }
    /* eslint-enable */
  },
  data () {
    return {
      typingTimer: null,
      submitState: false,
      query: '',
      patientForm: Object.assign({}, { cca_id: this.ccaId }, this.respondent, { patient_relation: null, role: null }),
      originalPatientForm: null,
      mobile_clean: '',
      caregiversData: CaregiversData,
      disabledDates: {
        from: new Date()
      },
      forceCreation: (this.respondent.id),
      forceCreationWithExistingRespondent: (this.respondent.id)
    }
  },
  computed: {
    doneTypingInterval () {
      return this.currentBrowser && this.currentBrowser.isExplorer ? 800 : 600
    },
    isEmrEmail () {
      return this.respondent.email_emr.length && this.respondent.email !== this.respondent.email_emr
    },
    isEmrPhone () {
      return this.respondent.mobile_number_emr.length && this.respondent.mobile_number !== this.respondent.mobile_number_emr
    },
    respondentList () {
      if (this.query.length < 2) {
        return []
      }
      const query = this.query.toLowerCase()
      return this.respondents.filter(r => {
        // Person already assigned / Patient does not show.
        if (this.assignments.find(e => parseInt(e.user_id) === parseInt(r.id))) {
          return false
        }

        // Person with matches on query show.
        return (r.name && r.name.toLowerCase().includes(query)) || (r.first_name && r.first_name.toLowerCase().includes(query)) ||
          (r.last_name && r.last_name.toLowerCase().includes(query)) || `${r.id}`.includes(query)
      })
    },
    showOnlySearchRespondent () {
      return this.action === 'create' && !this.forceCreation
    },
    disableSubmitButton () {
      if (this.errors.items.length) {
        return true
      }

      if (this.submitState) {
        return true
      }

      return false
    },
    title () {
      if (this.action === 'create' && !this.forceCreationWithExistingRespondent) {
        return this.$t('createRespondent', { patient: this.assignments[0].patient_name })
      }
      return this.$t('updateRespondent', { patient: this.assignments[0].patient_name })
    },
    showButtons () {
      if (this.readOnly) { return false }
      if (!this.isPatientInformation || this.patientsByProgramsUpdate) { return true }

      const keys = Object.keys(this.fields)
      if (!keys.length) {
        return false
      }
      return this.formDirty
    },
    saveText () {
      return this.action === 'update' ? this.$t('confirm') : this.$t('continue')
    },
    isPatientInformation () {
      return this.$route.name === 'ClinicianPatientInformation' || this.patientsByProgramsUpdate
    },
    isEmailNotificationEnabled () {
      return !!(!this.errors.has('email') && this.patientForm && this.patientForm.email)
    },
    isMobileNotificationEnabled () {
      return (!this.errors.has('mobile') && this.mobile_clean)
    },
    computedForm () {
      return Object.assign({}, this.patientForm)
    },
    ...mapGetters({
      singlePatient: 'getSinglePatient',
      assignments: 'getAssignments',
      respondents: 'getRespondents',
      settings: 'generalSettings',
      formDirty: 'getActiveFormDirty',
      currentBrowser: 'getCurrentBrowser'
    })
  },
  watch: {
    'respondent.email' (newValue) {
      this.patientForm.email = newValue
    },
    'respondent.email_notification' (newValue) {
      this.patientForm.email_notification = newValue
    },
    'respondent.mobile_notification' (newValue) {
      this.patientForm.mobile_notification = newValue
    },
    'respondent.mobile_number' (newValue) {
      this.patientForm.mobile_number = newValue
    },
    isMobileNotificationEnabled (newValue) {
      if (!newValue) {
        this.patientForm.mobile_notification = false
      }
    },
    isEmailNotificationEnabled (newValue) {
      if (!newValue) {
        this.patientForm.email_notification = false
      }
    },
    computedForm: {
      handler (n, o) {
        const isDirty = Object.keys(this.patientForm).some(field => {
          const oldValue = field === 'mobile_number' ? this.getCleanPhone(o[field]) : o[field]
          const newValue = field === 'mobile_number' ? this.getCleanPhone(n[field]) : n[field]
          return newValue !== oldValue
        })
        this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', isDirty)
      },
      deep: true
    }
  },
  mounted () {
    this.initForm()
  },
  destroyed () {
    this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
  },
  methods: {
    selectRespondent (option) {
      this.$store.dispatch('GET_PATIENT', option.id).then(() => {
        this.$validator.reset()
        this.forceCreation = true
        this.forceCreationWithExistingRespondent = true
        this.patientForm = Object.assign({}, { cca_id: this.ccaId }, this.singlePatient, { patient_relation: null, role: null })
      })
    },
    addAsNew () {
      this.patientForm = Object.assign({}, { cca_id: this.ccaId }, {}, { patient_relation: null, role: null })
      this.forceCreation = true
      this.forceCreationWithExistingRespondent = false
      this.$validator.reset()
    },
    searchAgain () {
      this.forceCreation = false
      this.forceCreationWithExistingRespondent = false
      this.query = ''
    },
    getAssignment () {
      return this.assignments.find(a => parseInt(a.assignment_id) === parseInt(this.ccaId) && parseInt(a.user_id) === parseInt(this.respondent.id))
    },
    initForm () {
      const assignment = this.patientsByProgramsUpdate ? this.getAssignment() : this.activeAssignment
      this.$validator.localize('en', this.errorsDictionary)
      this.patientForm.patient_relation = this.isPatientInformation && assignment ? assignment.patient_relation_id : null
      this.originalPatientForm = { ...this.patientForm }
    },
    cancel () {
      this.$emit('cancel')
      if (this.$route.name === 'ClinicianPatientInformation') {
        this.patientForm = { ...this.originalPatientForm }
        this.query = ''
        this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
      }
    },
    submitRespondent () {
      if (!this.readOnly) {
        this.$validator.validateAll().then((valid) => {
          if (valid) {
            this.submitState = true
            const respondentData = { ...this.patientForm }
            respondentData.mobile_number = this.mobile_clean
            this.validateNotifications()
            this.$emit('submitRespondent', respondentData)
            this.patientForm = { ...respondentData }
            this.originalPatientForm = { ...this.patientForm }
          }
        })
      }
    },
    submited () {
      this.submitState = false
    },
    validateNotifications () {
      let alertMsg = false
      if (!this.patientForm.email && !this.patientForm.mobile_number.replace(/\D/g, '')) {
        alertMsg = this.$t('noContactInfo')
      } else if (!this.canSendNotification()) {
        alertMsg = this.$t('notificationsOff')
      }
      if (alertMsg) {
        this.$toast.warning({ message: this.getNotificationMessage(alertMsg) })
      }
    },
    canSendNotification () {
      return (this.patientForm.email && this.patientForm.email_notification) || (this.patientForm.mobile_number && this.patientForm.mobile_notification)
    },
    getNotificationMessage (msg) {
      return this.$t('alertCantSendNotification', { text: msg })
    },
    setDirty (field) {
      this.$validator.flag(field, { dirty: true })
    },
    cleanPhone (phone) {
      this.mobile_clean = phone.replace(/\D/g, '')
      if (this.mobile_clean) {
        this.setDirty('mobile')
      }
    },
    searchChange (query) {
      this.query = ''
      clearTimeout(this.typingTimer)
      if (query.length < 2) {
        this.$store.commit(GET_RESPONDENTS, [])
        return false
      }
      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(() => { this.doneTyping(query) }, this.doneTypingInterval)
    },
    doneTyping (query) {
      const loading = this.$loading.show()
      this.$store.dispatch(GET_RESPONDENTS, {
        forceAllPatients: true,
        paginate: false,
        ignorePatientSelector: true,
        searchTerm: query
      }).then(() => {
        this.query = query
      }).finally(() => {
        loading.hide()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .error {
    float: none;
    padding-bottom: 0;
  }
  .emr-contact {
    margin: 8px 0 0 12px;
    font-size: 12px;
    font-weight: 300;
    span {
      color: #8097AD;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .search-respondent {
    display:none;
    .form-group {
      label {
        font-size: 13px;
      }
    }
  }
  .show-search-respondent {
    .search-respondent {
      display: block;
    }
    form {
      display: none !important;
    }
  }
  .searchInfo {
    font-size: 13px;
    display: block;
    margin-top: -12px;
    margin-bottom: 20px;
    width: 215px;
    a, a:hover {
      color: #2068ac;
      cursor: pointer;

    }
  }

  .addAsNew {
    color: #2068ac !important;
  }

  .clinician-overlay {
    .form-group {
      p {
        padding-left: 0px !important;
      }
      label {
        padding-left: 0px;
      }
    }
    .start-name-group {
      width:215px;
      display:inline-block;
    }
    .middle-name-group {
      width:135px;
      margin-left: 8px;
      display:inline-block;
    }
    .last-name-group {
      width:170px;
      margin-left: 8px;
      display:inline-block;
    }
  }

  .multiselect {
      width: 560px;
    }
</style>
