<template>
  <div
    v-if="clinicalUserEditing && clinicalUserEditing.id"
    class="extra-footer-info"
  >
    <div>
      <span>{{ $t('lastLogin') }}</span>
      <span>{{ lastLoginDate }}</span>
    </div>
    <div v-if="clinicalUserEditing.last_activity && false">
      <span>{{ $t('latest_activity_codes.value_' + clinicalUserEditing.last_activity.code) }}</span>
      <span>{{ lastActivityDate }}</span>
    </div>
  </div>
</template>

<script>
import { Helpers } from '@/mixins/Helpers'
import { mapGetters } from 'vuex'
import { LastLoginAndLastActivity } from '@/mixins/LastLoginAndLastActivity'

export default {
  name: 'SettingsPatientUsersBottomPanelLeft',
  mixins: [Helpers, LastLoginAndLastActivity],
  computed: {
    ...mapGetters({
      clinicalUserEditing: 'getSettingEditingInForm'
    })
  }
}
</script>
