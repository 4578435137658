<template>
  <ul class="nav nav-pills sub-header justify-content-end">
    <li
      v-for="(filter, i) in filters"
      :key="i"
      class="nav-item dropdown view-selector"
      @mouseover="filter.show = true"
      @mouseout="hml"
    >
      <a
        class="nav-link"
        :class="{active: filter.show}"
        @mouseover="filter.show = !filter.show"
      >{{ filter.label }}</a>
      <div
        ref="dropdownMenu"
        class="dropdown-menu dropdown-content"
        :class="{show: filter.show}"
      >
        <a
          v-for="(option, j) in filter.options"
          :key="j"
          class="dropdown-item"
          :class="{highlight: selected.includes(option.emitValue)}"
          @click="emitChange(option.emitValue, filter.emitMethod)"
        >{{ option.label }}</a>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ClinicianLayoutSubHeader',
  props: ['selected', 'viewFilter', 'unacknowledgedFilter'],
  data () {
    const filters = []
    if (this.viewFilter) {
      filters.push({
        label: 'View',
        show: false,
        emitMethod: 'onChange',
        options: [
          {
            emitValue: 'all',
            label: 'All Patients'
          },
          {
            emitValue: 'active',
            label: 'Active Patients'
          }
        ]
      })
    }
    if (this.unacknowledgedFilter) {
      filters.push({
        label: 'View',
        show: false,
        emitMethod: 'onChange',
        options: [
          {
            emitValue: 'allAlerts',
            label: 'All alerts'
          },
          {
            emitValue: 'unacknowledged',
            label: 'Active'
          }
        ]
      })
    }
    return {
      filters: filters
    }
  },
  methods: {
    emitChange (val, method) {
      this.$emit(method, val)
      this.$nextTick().then(() => {
        this.hideAll()
      })
    },
    hideAll () {
      this.filters.forEach(f => {
        f.show = false
      })
    },
    hml (event) {
      const exists = event.relatedTarget && event.relatedTarget.classList
      if (exists && !event.relatedTarget.classList.contains('nav-item')) {
        this.hideAll()
      }
    },
    handleMouseLeave (event) {
      const exists = event.relatedTarget && event.relatedTarget.classList
      const found = exists && !(event.relatedTarget.classList.contains('view-selector') || event.relatedTarget.classList.contains('nav-link'))
      if (!found) {
        this.hideAll()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .sub-header {
    padding-right: 35px;
    min-height: 34px;
    border-top: 2px solid #f1f5f8;
    background: white;
    .nav-link {
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.31;
      letter-spacing: normal;
      color: #0a3560;
      padding: 0px 1rem;
      border-radius: 0;
      margin: 1px 0;
      &.active {
        background: #f1f5f8;
      }
    }
    .dropdown-content {
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 11;
      border-radius: 3px;
      background-color: white;
      border: solid 1px #0a356080;
      text-align: left;
      padding: 12px 0px;
      right: 0;
      left: auto;
      top: calc(100% + -2px);
      a {
        padding: 0px 15px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        display: block;
        line-height: 2.22;
        letter-spacing: normal;
        color: #66758f;
        &:hover {
          cursor: pointer;
          background-color: rgba(80, 227, 213, 0.2);
        }
        &.highlight {
          font-weight: bold;
        }
      }
    }
  }
</style>
