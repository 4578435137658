<template>
  <div>
    <div class="btn-group">
      <button
        v-tooltip="noLoginAsTooltipData"
        type="button"
        class="btn btn-sm btn-name"
        :class="`${iconClass} ${!loginAsEnabled ? 'disabled' : ''}`"
        @click="signIn"
      >
        {{ $t('sign_In') }} {{ user.first_name }}
      </button>
      <button
        type="button"
        :class="`${iconClass} ${keepOpenedClass}`"
        class="btn btn-sm dropdown-toggle dropdown-toggle-split"
        @click="openSubMenu"
      />
      <div
        class="dropdown-menu owl-menu"
        :class="{show: opened}"
      >
        <a
          v-if="showActivate"
          class="dropdown-item"
          href="javascript:void(0)"
          @click="activate"
        >{{ $t('activate') }}...</a>
        <a
          v-if="showSendInvite"
          class="dropdown-item"
          href="javascript:void(0)"
          @click="sendInvite"
        >{{ sendInviteText }}...</a>
        <a
          v-if="showSendReminder"
          class="dropdown-item"
          href="javascript:void(0)"
          @click="sendReminder"
        >{{ $t('sendReminder') }}...</a>
        <a
          v-if="showResetPassword"
          class="dropdown-item"
          href="javascript:void(0)"
          @click="resetPassword"
        >{{ $t('resetPasswordButtonText') }}...</a>
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          @click="helpSignIn"
        >{{ $t('helpUserSignIn', {name: user.first_name}) }}</a>
      </div>
      <EditPatientModalWrapper
        v-if="helpingSignIn"
        @close="helpingSignIn = false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { RespondentHelper } from '@/mixins/RespondentHelper'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import { PatientHelper } from '@/mixins/PatientHelper'
import { RespondentAccountActions } from '@/mixins/RespondentAccountActions'
import { INACTIVE_USER_STRING } from '@/store/modules/user/constants.js'
import EditPatientModalWrapper from '@/components/clinician/patients/EditPatientModalWrapper.vue'
import { EDITING_SETTING } from '@/store/modules/locations/constants'
import { GET_PATIENT_USER } from '@/store/modules/patientUsers/constants'
import { STATUS_ACTIVE } from '@/data/userStatusValues'
import { CHANGE_STATUS } from '@/store/modules/clinicalUsers/constants'

export default {
  name: 'SignInPatientButton',
  components: { EditPatientModalWrapper },
  mixins: [RespondentHelper, UserManagementComponentMixin, PatientHelper, RespondentAccountActions],
  props: ['user'],
  data () {
    return {
      opened: false,
      helpingSignIn: false,
      canActivate: true
    }
  },
  computed: {
    ...mapGetters({
      settings: 'generalSettings',
      singlePatientUser: 'getSinglePatientUser'
    }),
    loginAsEnabled () {
      // Session is not Available yet.
      if (!this.user.have_available_measures || !this.userHasAccessToLogInAsRespondent()) {
        return false
      }
      return true
    },
    sendInviteText () {
      return Number(this.user.invitation_sent) ? this.$t('reSendInvite') : this.$t('sendInvite')
    },
    showSendInvite () {
      return this.accountNeedSetup && this.notificationsEnabled
    },
    showActivate () {
      return this.user.status === INACTIVE_USER_STRING && this.canActivate
    },
    accountNeedSetup () {
      return Number(this.user.need_setup)
    },
    showSendReminder () {
      return this.user.have_available_measures && !this.accountNeedSetup && this.notificationsEnabled
    },
    showResetPassword () {
      return !this.accountNeedSetup && this.user.status !== INACTIVE_USER_STRING && this.notificationsEnabled
    },
    iconClass () {
      return this.isPatientRelation(this.user.patient_relation_id) ? 'btn-secondary' : 'btn-outline-secondary'
    },
    keepOpenedClass () {
      return `keep_opened_${this._uid}`
    },
    hasPhone () {
      return this.settings.sms_enabled && this.user.contact_phone && this.user.contact_phone.value
    },
    hasEmail () {
      return this.user.contact_email && this.user.contact_email.value
    },
    noContactTooltipData () {
      return this.hasPhone || this.hasEmail ? false : { content: this.$t('noContactAvailable') }
    },
    noLoginAsTooltipData () {
      return this.loginAsEnabled ? false : { content: this.$t('noMeasuresAvailable') }
    }
  },
  created () {
    document.addEventListener('click', this.handleClickOutside)
  },
  methods: {
    ...mapMutations({
      setSettingToEdit: EDITING_SETTING
    }),
    helpSignIn () {
      this.$genericDispatch(GET_PATIENT_USER, this.user.user_id).then(() => {
        this.helpingSignIn = true
        this.setSettingToEdit(this.singlePatientUser)
      })
    },
    handleClickOutside (evt) {
      if (evt.target && evt.target.classList && !evt.target.classList.contains(this.keepOpenedClass)) {
        this.opened = false
      }
    },
    signIn () {
      if (this.loginAsEnabled) {
        this.loginAs(this.user.client_clinic_assignment_user_id || this.user.ccauId)
      }
    },
    openSubMenu () {
      this.opened = !this.opened
    },
    activate () {
      const params = {
        id: this.user.user_id,
        status: STATUS_ACTIVE
      }
      this.$genericDispatch(CHANGE_STATUS, params).then(() => {
        const assignmentId = this.user.assignment_id ? this.user.assignment_id : this.user.client_clinic_assignment_id
        const ccauId = this.user.ccauId ? this.user.ccauId : this.user.client_clinic_assignment_user_id
        const patientName = this.user.patient_name ? this.user.patient_name : `${this.user.patient_first_name} ${this.user.patient_last_name}`
        this.$store.dispatch('GET_ASSIGNMENTS', assignmentId).then(() => {
          this.$store.dispatch('SET_ASSIGNMENT_VIEW', ccauId)
        })
        this.$store.dispatch('GET_PATIENT', this.user.user_id)
        this.canActivate = false
        this.$toast.success({
          message: this.$t('userChangedStatus',
            {
              user: patientName,
              status: this.$t('activated')
            })
        })
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.btn-name {
  min-width: 160px;
  &.btn-secondary {
    border-right: 2px solid #8097AD !important;
  }
}
</style>
