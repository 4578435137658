<template>
  <div
    class="filters-container"
    :class="{condensed}"
  >
    <div
      class="btn-group"
      :class="{show: opened}"
    >
      <a
        class="trigger"
        @click="$emit('toggleOpened')"
      ><span>{{ selectedLabel }}</span></a>
      <i
        class="fas filter-toggle"
        :class="{'fa-caret-down': !opened, 'fa-caret-up': opened}"
        @click="$emit('toggleOpened')"
      />
      <div
        class="dropdown-menu filter-content-menu"
        :class="{show: opened}"
      >
        <div class="slot-header">
          <slot
            name="header"
            :selectNone="selectNone"
            :selectAll="selectAll"
          />
        </div>
        <i
          v-if="!hideCloseIcon"
          class="fal fa-times"
          @click="$emit('close')"
        />
        <div
          v-for="item in items"
          :key="item[valueKey]"
        >
          <div
            class="dropdown-item"
            :class="{'row-active': isCondensedAndActive(item)}"
          >
            <input
              v-show="false"
              :id="`accountStatus${item[valueKey]}`"
              v-model="filterSelected"
              type="checkbox"
              :name="`accountStatus${item[valueKey]}`"
              :value="item[valueKey]"
            >
            <label :for="`accountStatus${item[valueKey]}`">
              <i
                v-if="!condensed"
                :class="{'fal fa-check-square': selected.includes(item[valueKey]), 'fal fa-square': !selected.includes(item[valueKey])}"
              />
              {{ item[labelKey] }}
            </label>
            <i
              v-show="isCondensedAndActive(item)"
              class="fa fa-check color-success pl-2"
            />
          </div>
          <hr v-if="item.showDividerBottom">
        </div>
        <div class="button-container">
          <button
            type="button"
            class="btn btn-secondary btn-block btn-apply"
            :class="{'btn-sm my-2': condensed}"
            @click="$emit('applyFilter')"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterContent',
  props: ['opened', 'selectedLabel', 'items', 'selected', 'customLabelKey', 'customValueKey', 'customSubmit', 'hideCloseIcon', 'condensed'], // eslint-disable-line vue/require-prop-types
  data () {
    return {
      filterSelected: this.selected
    }
  },
  computed: {
    buttonText () {
      return this.customSubmit || this.$t('applyFilter')
    },
    labelKey () {
      return this.customLabelKey || 'label'
    },
    valueKey () {
      return this.customValueKey || 'value'
    }
  },
  watch: {
    items () {
      // OWL-7927 when items change, reset the selected to include all items
      this.filterSelected = this.items.map(item => item[this.valueKey])
    },
    filterSelected () {
      this.$emit('input', this.filterSelected)
    }
  },
  methods: {
    isCondensedAndActive (item) {
      return this.condensed && this.selected.includes(item[this.valueKey])
    },
    selectAll () {
      this.filterSelected = this.items.map(i => i[this.customValueKey])
    },
    selectNone () {
      this.filterSelected = []
    }
  }
}
</script>
